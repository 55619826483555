<template>
  <div>
    <!-- <div class="_1170">
      <div class="top-ctrl w-clearfix"></div>
    </div> -->
    <div class="white-box">
      <div class="side-head-wrap">
        <p class="board-small-head">Board details</p>
        <p>General information about your Board<br /></p>
      </div>
      Logo
      <div class="input-image" @click="triggerInput">
        <div class="new-logo-up">
          <img
            v-if="details.src"
            :src="details.src.imageURL || details.src"
            alt="your image"
          />
          <div v-else>
            Add an Image
            <span><br />Choose File to Upload<br />(max 10mb)</span>
          </div>
          <input
            accept="image"
            class=""
            fileupload=""
            type="file"
            @input="addLogo"
            @change="onFileChange"
            ref="fileInput"
          />
        </div>
      </div>
      <div class="content-box">
        <div class="w-form">
          <form id="email-form" name="email-form" data-name="Email Form">
            <input
              type="text"
              class="text-field w-input"
              maxlength="256"
              name="BoardName"
              data-name="BoardName"
              placeholder="Board Name"
              v-model="details.name"
              id="BoardName"
            />
            <textarea
              placeholder="Subtitle"
              v-model="details.tagline"
              maxlength="5000"
              id="Subtitle"
              name="Subtitle"
              data-name="Subtitle"
              class="text-field big w-input"
            />
            <textarea
              placeholder="English terms of use text"
              v-model="details.disclaimerContentEn"
              maxlength="5000"
              id="Subtitle"
              name="Subtitle"
              data-name="Subtitle"
              class="text-field big w-input"
            />
            <textarea
              placeholder="טקסט תנאי שימוש"
              v-model="details.disclaimerContentHe"
              maxlength="5000"
              id="Subtitle"
              name="Subtitle"
              data-name="Subtitle"
              class="text-field big w-input"
              dir="rtl"
            />

            <div class="info-line">
              <p class="info-title">API ID:</p>
              <p class="info-txt">{{ this.details.id }}</p>
            </div>
            <div class="info-line">
              <p class="info-title">Created:</p>
              <p class="info-txt">{{ this.details.createdDate }}</p>
            </div>
            <div class="info-line">
              <p class="info-title">Last update:</p>
              <p class="info-txt">{{ this.details.lastUpdated }}</p>
            </div>

            <a
              class="delete-btn w-button"
              style="background: #3fcfff"
              @click="updateBoard"
              >Update</a
            >
          </form>
          <!--                        <div class="w-form-done">-->
          <!--                            <div>Thank you! Your submission has been received!</div>-->
          <!--                        </div>-->
          <!--                        <div class="w-form-fail">-->
          <!--                            <div>Oops! Something went wrong while submitting the form.</div>-->
          <!--                        </div>-->
        </div>
      </div>
    </div>

    <!-- <div class="white-box">
      <div class="side-head-wrap">
        <p class="board-small-head">Remove Board</p>
        <p>
          Remove this board from your account.<br />Notice: This action can not
          be undone<br />
        </p>
      </div>

      <div class="content-box">
        <dropdown>
          <a class="delete-btn w-button" @click="deleteBoard()">Remove</a>
        </dropdown>
      </div>
    </div> -->

    <!--            <div class="white-box">-->
    <!--                <div class="side-head-wrap">-->
    <!--                    <p class="board-small-head">Color Theme</p>-->
    <!--                    <p>Affects the color palette of the board<br></p>-->
    <!--                </div>-->
    <!--                <div class="content-box">-->
    <!--                    <div v-for="(color, i) in colors" :key="i" class="color-pick " :class="{'active':details.boardColor==i}"></div>-->
    <!--                    <div class="color-pick red-color"></div>-->
    <!--                    <div class="color-pick purple-color"></div>-->
    <!--                    <div class="color-pick green-color"></div>-->
    <!--                    <div class="color-pick dark-color"></div>-->
    <!--                    <div class="color-pick"></div>-->
    <!--                </div>-->
    <!--            </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import dropdown from "../components/dropdown.vue";

export default {
  // components: { dropdown },
  name: "GeneralBoardEditing",
  data() {
    return {
      // colors: [
      //   {name: "default"},
      //   {name: "red-color"},
      //   {name: "purple-color"},
      //   {name: "green-color"},
      //   {name: "dark-color"}
      // ],
      details: {
        name: "",
        tagline: "",
        disclaimerContentEn: "",
        disclaimerContentHe: "",
        id: "",
        src: null,
        newLogo: {},
        createdDate: "",
        lastUpdated: "",
        // boardColor: 1
      },
    };
  },
  computed: {
    ...mapGetters(["getSelectedBoard", "getUser", "get"]),
  },
  methods: {
    triggerInput() {
      this.$refs?.fileInput?.click?.();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.details.src = URL.createObjectURL(file);
    },
    addLogo(event) {
      const file = event.target.files[0];
      this.onFileChange(event);
      this.details.newLogo = {};
      this.details.newLogo.selectedImage = file;
    },
    buildDate(notFormattedDate) {
      if (notFormattedDate) {
        let date = new Date(notFormattedDate);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      }
      return "";
    },
    deleteBoard() {
      console.log(
        "deleting board " +
          this.getSelectedBoard._id +
          "\n(please complete this function)"
      );
      this.$store.dispatch("removeBoard", this.details.id);
    },
    async updateBoard() {
      const newLogo = this.details.newLogo?.selectedImage;
      console.log(`image`, newLogo);
      console.log(
        "updating board " +
          this.getSelectedBoard._id +
          "\n(please complete this function)",
        this.details
      );
      const result = await this.$store.dispatch("updateBoard", this.details);
      console.log(`result`, result);
    },
  },
  async mounted() {
    // this.details.name = this.getSelectedBoard.name;
    // this.details.tagline = this.getSelectedBoard.tagline;
    // this.details.disclaimerContentEn =
    //   this.getSelectedBoard.disclaimerContentEn;
    // this.details.disclaimerContentHe =
    //   this.getSelectedBoard.disclaimerContentHe;
    this.details = { ...this.getSelectedBoard };
    this.details.id = this.getSelectedBoard._id;
    this.details.src = this.getSelectedBoard.topLogoGallery[0];
    this.details.createdDate = this.buildDate(
      this.getSelectedBoard._createdDate
    );
    this.details.lastUpdated = this.buildDate(
      this.getSelectedBoard._lastUpdatedDate
    );
  },
};
</script>

<style scoped>
.content-box {
  width: 100%;
}
.side-head-wrap {
  width: 100%;
}
</style>
