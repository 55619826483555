<template>
  <div>
    <div class="comp-sel">
      <Recommendations
        v-show="false"
        v-if="!statsPopup[1]"
        @updateRecCount="updateRecCount"
        :board="getSelectedBoard"
      />
      <Popup v-model="statsPopup[1]">
        <div class="newcom-pop">
          <div>
            <label class="newcom-pop-head">Recommendations</label>
            <Recommendations
              @updateRecCount="updateRecCount"
              :board="getSelectedBoard"
            />
          </div>
        </div>
      </Popup>
      <div style="padding-top: 30px" />
      <div
        class="stats"
        :loading="isLoading"
      >
        <div
          v-for="(b, index) of boxes"
          :key="b.title"
        >
          <v-btn
            :disabled="!!isLoading"
            @click="!isLoading && toggleStatsPopup(index)"
            outlined
            v-show="b.type === 'reqs' || b.count"
          >
            <div>{{ b.title }}</div>
            <div class="stats-num">
              {{ b.type === "reqs"
                  ? b.value.reduce((acc, cur) => (acc += cur.count), 0)
                  : b.count
              }}
            </div>
          </v-btn>
        </div>
        <div v-if="!statsPopup[1]">
          <Popup
            class="boxPopup"
            v-for="(b, index) of boxes"
            :key="b.title + 'pop'"
            v-model="statsPopup[index]"
          >
            <div class="newcom-pop">
              <div class="selectWrap">
                <label class="newcom-pop-head">{{ b.title }}</label>
                <small
                  v-if="b.type === 'reqs'"
                  style="color: red; padding: 5px"
                > * missing required information </small>
                <div class="newcom-pop-cont">
                  <div v-if="b.type === 'reqs'">
                    <v-tabs
                      v-model="tabModel"
                      centered
                    >
                      <v-tab
                        :ripple="false"
                        :hide-slider="true"
                        v-for="(val, i) of b.value"
                        :key="val.title"
                      >
                        <v-badge
                          v-if="val.count"
                          color="#047cff"
                          inline
                          :content="val.count"
                        >
                          {{ val.title }}
                        </v-badge>
                        <span v-else>{{ val.title }}</span>
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabModel">
                      <v-tab-item
                        :transition="false"
                        v-for="(val, i) in b.value"
                        :key="`tab-${i}`"
                      >
                        <div v-show="tabModel === i">
                          <div
                            class="comp-row"
                            v-for="company of val.companies"
                            :key="company._id"
                          >
                            <img
                              v-bind:src="
                                (hasCompanyRef(company) &&
                                  hasCompanyRef(company).logo.imageURL) ||
                                (company.logo && company.logo.imageURL) ||
                                fallbackImg
                              "
                              class="comp-logo"
                            />
                            <p class="comp-name">
                              {{ (hasCompanyRef(company) &&
                                  hasCompanyRef(company).name) ||
                                  company.name
                              }}
                              <span
                                v-if="!hasCompanyRef(company)"
                                style="color: red; padding: 5px"
                              > * </span>
                            </p>
                            <!-- <span
                              v-if="
                                hasCompanyRef(company) &&
                                  hasCompanyRef(company).jobs > -1
                              "
                              class="comp-pos "
                              :style="hasCompanyRef(company).jobs || 'color: red'"
                            >
                              {{
                                hasCompanyRef(company).jobs +
                                  ` Job${hasCompanyRef(company).jobs === 1 ? "" : "s"}`
                              }}
                            </span>
                            <small v-else class="comp-pos " :style="'color: red'"
                              >No Job Data Available</small
                            > -->
                            <div
                              class="actions"
                              v-if="!reqByUser(company) || i"
                            >
                              <small
                                v-if="reqByUser(company)"
                                class="reqNote"
                              >Request By Visitor</small>
                              <v-btn
                                v-if="!hasCompanyRef(company)"
                                outlined
                                class="add"
                                @click="
                                  editRequest(index, company, false, false)
                                "
                              > edit request </v-btn>
                              <v-btn
                                v-else
                                @click="editRequest(index, company)"
                              > view request </v-btn>
                              <!-- <v-icon>mdi-alert-circle</v-icon> -->
                              <!-- <v-btn class="ignore">Ignore</v-btn> -->
                            </div>
                            <div
                              v-else
                              class="actions altActions"
                            >
                              <small class="reqNote">Request By Visitor</small>
                              <v-btn
                                outlined
                                class="add"
                                @click="editRequest(index, company, true)"
                              > approve </v-btn>
                              <v-btn
                                outlined
                                class="ignore"
                                @click="editRequest(index, company, false)"
                              > reject </v-btn>
                            </div>
                          </div>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                  <div
                    class="maintenance"
                    v-else
                  >
                    <div
                      v-for="company of b.value"
                      :key="company.itemRefID"
                    >
                      <div class="comp-row-wrap">
                        <router-link :to="
                          '/boards/' +
                          getSelectedBoard._id +
                          `/${company.item.manualCompany
                            ? 'manual-company'
                            : 'company'
                          }/` +
                          company.itemRefID
                        ">
                          <img
                            v-bind:src="
                              company.item.logo.imageURL || fallbackImg
                            "
                            class="comp-logo"
                          />
                          <p class="comp-name">{{ company.item.name }}<br /></p>
                        </router-link>
                        <div
                          v-if="positionCount[company.itemRefID] > -1"
                          class="comp-pos"
                          :style="
                            positionCount[company.itemRefID] || 'color: red'
                          "
                        >
                          {{ positionCount[company.itemRefID] +
                              ` Job${positionCount[company.itemRefID] === 1 ? "" : "s"
                              }`
                          }}
                        </div>
                        <p
                          v-if="getPromotedJobs(company.itemRefID)"
                          class="comp-pos promoCount"
                        >
                          <span> | </span>
                          {{ getPromotedJobs(company.itemRefID)
                              ? `${getPromotedJobs(company.itemRefID)} Promoted`
                              : ""
                          }}
                          <span> | </span>
                        </p>
                        <dropdown class="comp-btn-wrap">
                          <div
                            class="comp-pause delete"
                            @click="pauseCompany(company.item, index)"
                          > Remove </div>
                        </dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </div>
      <div class="comp-top w-clearfix">
        <p
          class="comp-new"
          @click="pop = true"
        >+ Add New Companies<br /></p>
      </div>
      <Popup v-model="pop">
        <CompanyForm
          :compReqs="getRequestedCompanies"
          :company="setCompany"
        />
      </Popup>
      <div style="
          text-align: center;
          text-align: center;
          color: grey;
          padding: 20px;
        ">
        {{ `${isLoading ? "Syncing" : ""} ${reducePosCount(
            positionsByComp
          )}  Job${reducePosCount(positionsByComp) === 1 ? "" : "s"} from ${Object.values(positionsByComp).length
            } Companies`
        }}
      </div>
      <div>
        <p v-if="getCompanyRequests.length">Requested Companies</p>
        <div
          v-for="company in getCompanyRequests"
          :key="company._id"
          class="requested-companies"
        >
          <p class="comp-name">{{ company.name }}<br /></p>
          <p class="comp-pos">{{ company.link }}<br /></p>
          <p class="comp-stat"> Current Status: <span class="stat">{{ company.status.value }}</span>
          </p>
        </div>
      </div>
      <div v-if="joinedComps && finishedLoading">
        <!-- !isLoading && -->
        <div v-if="!statsPopup[0] && !statsPopup[1]">
          <div
            v-for="(company, index) in sortCompsByNumPositions(joinedComps)"
            :key="company._id"
          >
            <v-lazy>
              <div class="comp-row">
                <router-link :to="
                  '/boards/' +
                  getSelectedBoard._id +
                  `/${company.item.manualCompany ? 'manual-company' : 'company'
                  }/` +
                  company.itemRefID
                ">
                  <img
                    v-bind:src="company.item.logo.imageURL || fallbackImg"
                    class="comp-logo"
                  />
                  <p class="comp-name">{{ company.item.name }}<br /></p>
                </router-link>
                <span
                  v-if="positionCount[company.itemRefID] > -1"
                  class="comp-pos"
                  :style="positionCount[company.itemRefID] || 'color: red'"
                >
                  {{ positionCount[company.itemRefID] +
                      ` Job${positionCount[company.itemRefID] === 1 ? "" : "s"}`
                  }}
                </span>
                <p
                  v-if="getPromotedJobs(company.itemRefID)"
                  class="comp-pos promoCount"
                >
                  <span> | </span>
                  {{ getPromotedJobs(company.itemRefID)
                      ? `${getPromotedJobs(company.itemRefID)} Promoted`
                      : ""
                  }}
                  <span> | </span>
                </p>
                <Popup
                  v-model="pop2[index]"
                  :value="pop2[index]"
                >
                  <div class="newcom-pop">
                    <div class="selectWrap">
                      <label class="newcom-pop-head">Active Branches</label>
                      <div class="newcom-pop-cont">
                        <v-select
                          :items="company.item.branchesNestedCollection"
                          v-model="companyBranches[company.itemRefID]"
                          @change="updateCompanyBranches"
                          label="branches"
                          :item-text="'rawLocation' || 'address'"
                          item-value="_id"
                          multiple
                          append-icon="mdi-plus"
                          no-data-text="No branches exist"
                        >
                          <!-- class="b-select" -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index <= 1">
                              <span>{{ item.rawLocation ||
                                  item.address ||
                                  item.branchName
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 2"
                              class="grey--text text-caption"
                            > (+{{ /*compBranchLength(company) &&*/ compBranchLength(company) }} others) </span>
                          </template>
                        </v-select>
                      </div>
                      <div class="btn-pop-up-wrap">
                        <button
                          class="pop-btn"
                          @click="save"
                        > save changes </button>
                      </div>
                    </div>
                  </div>
                </Popup>
                <span
                  class="openActiveComps"
                  @click="openPop2(index)"
                  :class="{ noBranchWarn: !compBranchLength(company) }"
                >
                  {{ compBranchLength(company) }} Branches <v-icon
                    style="vertical-align: baseline"
                    color="#047cff"
                  > mdi-map-marker-plus </v-icon>
                  <small
                    class="openActiveComps"
                    v-if="
                      !compBranchLength(company) &&
                      checkLength(company.item.branchesNestedCollection)
                    "
                  > (Select a branch to add jobs) </small>
                </span>
                <dropdown class="comp-btn-wrap">
                  <div
                    class="comp-pause delete"
                    @click="pauseCompany(company.item, index)"
                  > Remove </div>
                </dropdown>
              </div>
            </v-lazy>
          </div>
          <div
            v-for="(company, index) in getBoardOfflineCompanies"
            :key="company._id"
          >
            <div class="comp-row unselected-row">
              <img
                v-bind:src="company.logo.imageURL || fallbackImg"
                class="comp-logo"
              />
              <p class="comp-name">{{ company.name }}<br /></p>
              <span
                v-if="company.jobs > -1"
                class="comp-pos"
                :style="company.jobs || 'color: red'"
              >
                {{ company.jobs + ` Job${company.jobs === 1 ? "" : "s"}` }}
              </span>
              <dropdown class="comp-btn-wrap">
                <div
                  class="comp-pause"
                  style="background: #e7e7e7"
                  @click="playCompany(company, index, true)"
                > Activate </div>
                <div
                  class="comp-pause delete"
                  @click="playCompany(company, index)"
                > Remove </div>
              </dropdown>
            </div>
          </div>
          <div
            v-for="company in getRequestedCompanies"
            :key="company._id"
          >
            <div class="comp-row">
              <img
                :src="getCompLogo(company)"
                class="comp-logo"
              />
              <p class="comp-name">{{ company.name }}<br /></p>
              <p class="comp-pos">
                {{ reqByUser(company) ? "Request By Visitor" : "Requested"
                }}<br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="comp-top w-clearfix"
      >
        <p style="font-weight: bold"> You don't have any companies yet. Click on Add New Companies </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Popup from "@/components/Popup";

import DATA_LOADING_STATE from "../enums/index";
import CompanyForm from "../components/CompanyForm";
import Dropdown from "../components/dropdown.vue";
import Recommendations from "../components/Recommendations.vue";
import fallbackImg from "@/assets/images/light-logo.svg";
import { uniqBy } from "lodash";


export default {
  name: "CompaniesSelection",
  components: { Popup, CompanyForm, Dropdown, Recommendations },

  data() {
    return {
      finishedLoading: false,
      companyBranches: {},
      pop: false,
      pop2: [],
      statsPopup: [],
      checkRegistration: [],
      subdomain: "",
      positionsByComp: {},
      isLoading: true,
      totalJobs: 0,
      statBoxes: [
        { title: "Requests", count: 0, companies: [] },
        { title: "Done", count: 0, companies: [] },
        { title: "Issues", count: 0, companies: [] },
      ],
      tabModel: null,
      recCount: 0,
      setCompany: null,
      fallbackImg,
    };
  },
  methods: {
    reqByUser(comp) {
      const status = comp?.status?.value;
      return !!status?.includes?.("User");
    },
    getCompLogo(comp) {
      return (
        (comp?.logo && comp?.logo?.imageURL) ||
        comp?.companyRef?.[0]?.item?.logo?.imageURL ||
        fallbackImg
      );
    },
    editRequest(index, company, isApprove, doCheck = true) {
      if (this.reqByUser(company) && doCheck) {
        this.$store.dispatch("changeReqStatus", { company, isApprove });
        return this.save();
      }
      this.setCompany = company;
      this.toggleStatsPopup(index);
      this.pop = true;
    },
    updateRecCount(count) {
      console.log(count);
      this.recCount = count;
    },
    filterReq(statIndex, types) {
      const pushData = (index, data) => {
        this.statBoxes[index]?.companies.push(data);
      };
      types.forEach((str) => {
        this.statBoxes[statIndex].count = this.getRequestedCompanies.filter(
          (r) => {
            const found = r?.status?.value === str;
            if (found) {
              pushData(statIndex, r);
            }
            return found;
          }
        ).length;
      });
    },
    hasCompanyRef(comp) {
      if (!comp?.companyRef?.[0]) return;
      return comp.companyRef[0].item;
    },
    reducePosCount(item) {
      return [
        ...Object.values(item),
        0 /* //! prevents error when empty array*/,
      ].reduce((acc, cur) => acc + cur);
    },
    sortCompsByNumPositions(comps) {
      const compsSorted = [];
      const compsSortedArr = Object.entries(this.positionsByComp)
        .sort((a, b) => b[1] - a[1])
        ?.map((x) => x[0]);
      comps.forEach(
        (c) => (compsSorted[compsSortedArr.indexOf(c.itemRefID)] = c)
      );
      return compsSorted;
    },
    compBranchLength(company) {
      return this.companyBranches?.[company?.itemRefID]?.length;
    },
    checkLength(item) {
      return item?.length;
    },
    openPop2(index) {
      this.pop2[index] = true;
      this.pop2 = [...this.pop2];
    },
    toggleStatsPopup(index) {
      this.statsPopup[index] = !this.statsPopup[index];
      this.statsPopup = [...this.statsPopup];
    },
    save() {
      this.$router.go();
    },
    async pauseCompany(company, index, notOnlyDelete) {
      await this.$store.dispatch("pauseCompany", {
        company: company,
        index: index,
        notOnlyDelete: notOnlyDelete,
      });
    },
    async updateCompanyBranches() {
      this.$store.dispatch("updateBranches", this.companyBranches);
    },
    async playCompany(company, index, notOnlyDelete) {
      console.log("get command to play company, index is:");
      console.log(index);
      await this.$store.dispatch("playCompany", {
        company: company,
        index: index,
        notOnlyDelete,
      });
    },
    async calcPositionNums() {
      this.$nextTick(async () => {
        if (!this?.joinedComps) return;
        // const temp = [];
        /**
         * const jointcamparr = uniqBy(this.joinedComps, (c) => c.itemRefID)
         *
         */
        const joinedCompsArr = uniqBy(this.joinedComps, (c) => c.itemRefID);

        const promises = joinedCompsArr.map(
          (comp) => (
            this.$store.dispatch((comp?.item?.manualCompany === true) ? "getManualJobs" : "getCompanyPositions", {
              companyID: comp.itemRefID,
              num: "0",
              removeData: 1,
            })
          )
        )
        const promisesSettled = await Promise.allSettled(promises);
        promisesSettled.forEach((res, i) => {
          // console.log('');
          if (!res?.value) return;
          let compData = res.value;
          let count = +res.value?.headers?.["item-count"] || 0;
          compData = count;
          this.positionsByComp = {
            ...this.positionsByComp,
            [joinedCompsArr[i].itemRefID]: compData,
          };

        })
        // const isSameItemRefIDs = promisesSettled.map((res, i) => ({
        //   joinedCompsArr: joinedCompsArr[i].itemRefID,
        //   promisesSettled: res?.value?.[0]?.company?.[0]?.itemRefID || res?.value?.[0]?.company?.[0]?.branchesNestedCollection?.[0]?.itemRefID || '',
        //   isTheSame: joinedCompsArr[i].itemRefID === res?.value?.[0]?.company?.[0]?.itemRefID || res?.value?.[0]?.company?.[0]?.branchesNestedCollection?.[0]?.itemRefID || ''
        // }))
        // console.log({ isSameItemRefIDs })
        // for (const comp of uniqBy(this.joinedComps, (c) => c.itemRefID)) {
        //   let compData;
        //   if (comp?.item?.manualCompany === true) {
        //     let res = await this.$store.dispatch("getManualJobs", {
        //       companyID: comp.itemRefID,
        //       num: "0",
        //       removeData: 1,
        //     });
        //     // console.log(`count1`, res?.headers?.["item-count"]);
        //     let count = +res?.headers?.["item-count"] || 0;
        //     compData = count;
        //   } else {
        //     let res = await this.$store.dispatch("getCompanyPositions", {
        //       companyID: comp.itemRefID,
        //       num: "0",
        //       removeData: 1,
        //     });
        //     // console.log(`count2`, res?.headers?.["item-count"]);
        //     let count = +res?.headers?.["item-count"] || 0;
        //     compData = count;
        //   }
        //   // temp.push({ [comp.itemRefID]: compData?.length });
        //   this.positionsByComp = {
        //     ...this.positionsByComp,
        //     [comp.itemRefID]: compData,
        //   };
        // }
        // console.log(temp);
        this.totalJobs = Object.values(this.positionsByComp)?.reduce(
          (a, b) => a + b,
          0
        );
        this.isLoading = false;
      });
    },
  },
  watch: {
    pop() {
      if (!this.pop) this.setCompany = null;
    },
    async getLoading() {
      console.log("loading changed to:");
      console.log(this.getLoading);
    },
    // async joinedComps() {
    //   await this.calcPositionNums();
    // },
    getRequestedCompanies() {
      [
        ["Pending", "User - Pending"],
        ["Done - Upload More info", "Syncing", "User - Approved"],
        ["Declined", "User - Rejected"],
      ].forEach((t, i) => this.filterReq(i, t));
    },
  },
  async mounted() {
    this.subdomain = window.location.host.split(".")[0];

    for (const comp of this.joinedComps) {
      this.companyBranches[comp.itemRefID] = comp.branchesNestedCollection?.map(
        (b) => b.itemRefID || b._id
      );
    }
    // eslint-disable-next-line no-unused-vars
    const comps = await this.joinedComps?.map?.((_) => false);
    this.pop2 = comps;
    console.log('chacking');
    this.finishedLoading = true;

    this.$nextTick(async () => {
      await this.calcPositionNums();
    });
    await this.$store.dispatch(
      "buildBoardCompaniesList",
      this.$route.params.id
    );
    // console.log(this.getManualCompanies);
    // console.log(this.joinedComps);
  },
  computed: {
    ...mapGetters([
      "getCompanyRequests",
      "getSelectedBoard",
      "getUser",
      "getCompanies",
      "getBoards",
      "getBoardActiveCompanies",
      "getBoardOfflineCompanies",
      "getLoading",
      "getRequestedCompanies",
      "getPromotedJobs",
      "getManualCompanies",
    ]),
    joinedComps() {
      // console.log(this.$store.state.selectedBoard.manualCompanies);
      const manualCompanies = this.getManualCompanies?.map?.((c) => ({
        branchesNestedCollection: c.branchesNestedCollection,
        item: c,
        itemRefID: c._id,
        value: c.name,
      })) || [];
      console.log('manualCompanies:', this?.getCompanies || []);
      return manualCompanies?.length
        ? [...manualCompanies, ...(this?.getCompanies || [])]
        : [...(this?.getCompanies || [])];
      // return [...this.getCompanies];
    },
    positionCount() {
      return this.positionsByComp;
    },
    // computeTotalCompaniesAmount() {
    //   console.log('getBoardActiveCompanies.length')
    //   console.log(this.getBoardActiveCompanies.length)
    //   return this.getBoardOfflineCompanies.length + this.getBoardActiveCompanies.length;
    // },
    showData() {
      return (
        this.getBoardActiveCompanies.length === 0 &&
        this.getBoardOfflineCompanies.length === 0 &&
        this.pop === false &&
        this.getLoading === DATA_LOADING_STATE.CREATING
      );
    },
    noPositionComps() {
      return this.joinedComps.filter((c) => !this.positionCount[c.itemRefID]);
    },
    boxes() {
      return [
        { title: "Requests", value: this.statBoxes, type: "reqs" },
        {
          title: "recommendations",
          value: [],
          count: this.recCount,
          companies: [],
        },
        {
          title: "Maintenance",
          value: this.noPositionComps,
          count: this.noPositionComps?.length,
          companies: this.noPositionComps,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.maintenance {
  .comp-row-wrap {
    border-bottom: 1px solid #e7e7e7 !important;
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px 0 8px;
  }

  .comp-row {
    display: inline-block;
  }
}

.v-application--is-ltr .v-tabs--centered>.v-tabs-bar .v-tabs-slider-wrapper+* {
  margin-left: unset;
}

.v-window.v-item-group {
  padding: 20px;
  margin: 0 -10px;
  width: calc(100% + 40px);
}

.v-window__container {
  width: calc(100% + 20px);
}

.v-tabs {
  margin-bottom: 10px;
}

.boxPopup {

  &,
  * {
    transition: unset !important;
  }
}

.stats .newcom-pop {
  max-width: 100%;

  .comp-row {
    padding: 0;
    margin-bottom: 18px;

    .comp-name {
      letter-spacing: 0;
    }

    .comp-logo {
      margin-top: -10px;
    }

    .v-btn {
      border-radius: 30px;
      padding: 4px 20px;
      box-shadow: none;
      box-sizing: content-box;
      color: #047cff;
      letter-spacing: 0;
      border: 1 px solid #e0ecfa;
      background: #e0ecfa;
    }

    .actions {
      margin: 5px auto 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .add {
        background: #047cff;
        color: white;
      }

      .ignore {
        background: #f9d0d0;
        color: darkred;
      }
    }

    .altActions {
      margin: 5px auto 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        max-width: 100px;
        margin: 0;
        padding: 2px 6px;
      }

      .add {
        background: #047cff;
        color: white;
      }

      .ignore {
        background: #f9d0d0;
        color: darkred;
      }

      .reqNote {
        position: absolute;
        top: -12px;
        right: 20px;
      }
    }
  }
}

.v-text-field.v-input--is-focused>.v-input__control>.v-input__slot:after,
.v-text-field>.v-input__control>.v-input__slot:after {
  border-color: #047cff !important;
  border-width: 1px !important;
}

.b-select .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  top: 248px !important;
}

.selectWrap .v-label {
  margin-top: 12px;
  font-weight: 400;
}

.selectWrap .v-select span {
  margin: 0;
}

.v-chip {
  background: #e0ecfa !important;
  margin: -2px 10px 10px 0 !important;
}

.v-input__slot {
  // padding-bottom: 10px;
}

.v-label.v-label--active.theme--light {
  margin-left: -20px;
  margin-top: -3px;
}

.v-select__selections {}

.pop-btn {
  border: 1px solid #047cff;
  border-radius: 30px;
  display: inline-block;
  padding: 4px 20px;
  transition: all 0.2s ease;
  cursor: pointer;
  text-align: center;
  min-width: 90px;
  flex-basis: 24%;
  background: #047cff;
  color: #fff;
}

.pop-btn:hover {
  filter: brightness(90%);
}

.newcom-pop-cont {
  padding: 26px;
  overflow: scroll;
  width: 100%;
}

.newcom-pop {
  max-width: 580px;
  margin: 0 auto;
  border-radius: 10px;
  background: white;
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.newcom-pop-head {
  background-image: linear-gradient(270deg, #047cff, #4be1ff);
  text-align: center;
  color: white;
  padding: 16px;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0;
}

.btn-pop-up-wrap {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.selectWrap {
  .v-input {
    width: 100%;
  }

  .v-label {
    top: -5px !important;
  }

  .v-chip--select {
    background: rgba(4, 124, 255, 0.19) !important;
  }
}

.promoCount {
  margin: 0 10px;
}

.comp-pos.promoCount {
  margin-bottom: 10px;
}

.comp-pos.promoCount span {
  opacity: 0;
}
</style>

<style scoped lang="scss">
.newcom-pop {
  max-width: 660px;
  max-height: 90vh;
  overflow: auto;
}

.stats {
  display: flex;
  // gap: 10px;
  // text-align: center;
  justify-content: center;
  margin: 0 0 20px;
  position: absolute;
  // div {
  //   width: 25vw;
  // }
}

.stats-num {
  color: #047cff;
  margin-left: 20px;
  font-weight: 600;
}

.promoCount {
  margin: 0 10px;
}

.openActiveComps {
  cursor: pointer;
  color: #047cff;
}

.noBranchWarn {
  color: #f00;
}

.openActiveComps .openActiveComps {
  color: #047cff;
}

.selectWrap .v-select span {
  margin: 10px;
}

.selectWrap .v-select__selections {
  margin-top: 75px;
}

.selectWrap {
  display: flex;
  flex-direction: column;
  align-items: end;

  label {
    text-align: center !important;
    width: 100%;
  }
}

.comp-pop-box {
  min-height: 400px;
  position: relative;
}

.compOptions span {
  background: #888;
}

.compOptions span.activeComp {
  background: #047cff;
}

.blue-checkbox {
  content: " ";
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border: solid 2px #eaf5ff;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: -2px;
  background: white;
  position: absolute;
  top: 7px;
  left: 0;
}

.update-company-branches {
  position: relative;
  padding-left: 22px;
}

.update-company-branches input {
  display: none;
  margin-right: 5px;
  position: relative;
  bottom: -1px;
}

label input:checked~.chip {
  background: #2196f3;
  color: white;
}

.branch {
  display: inline-block;
}

.branch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.unselected-row {
  background: #f7f7f7;
  border-radius: 5px;
  /*margin-bottom: 0px;*/
  margin-top: -10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  padding: 15px 20px;
}

.comp-logo {
  margin-bottom: 10px;
}

.unselected-row .comp-btn-wrap {
  position: absolute;
  top: 11%;
  right: 1%;
  bottom: auto;
  padding-top: 7px;
}

.requested-companies {
  background: #f7f7f7;
  margin-bottom: 10px;
  padding: 20px 20px 10px 20px;
  border-radius: 10px;
}

.comp-stat {
  display: inline-block;
  margin-left: 10px;
  padding-left: 10px;
  color: #95989b;
  position: relative;
  float: right;
}

.stat {
  color: #047cff;
}

.v-btn {
  margin: 0 10px;
}

@media screen and (max-width: 992px) {
  .comp-top .comp-new {
    float: none;
    width: 100%;
    text-align: center;
  }

  .requested-companies {
    text-align: center;
  }

  .comp-stat {
    float: none;
  }

  .stats {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .stats-num {
    margin-left: 4px;
  }

  .stats {
    display: block;
    justify-content: center;
    margin: 0px;
    position: relative;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 5px;
  }

  .v-btn {
    max-width: 300px;
    margin: 0 5px 10px;
  }

  .v-btn__content {
    color: inherit;
    display: flex;
    position: relative;
    transition: inherit;
    justify-content: center;
  }

  .comp-name {
    margin-right: 0;
  }

  .comp-pos.promoCount {
    margin-bottom: 10px;
  }

  .comp-pos.promoCount span {
    opacity: 0;
  }

  .comp-row {
    padding-left: 0px;
  }

  .v-card__title {
    height: 22px;
    font-size: 1rem;
    display: block;
    padding-top: 8px;
  }

  .stats {
    display: block;
    width: 100%;
  }
}
</style>
