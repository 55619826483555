/* eslint-disable no-unreachable */
// eslint-disable-next-line no-unused-vars
import * as axios from "axios";

// import Bagel from "@bageldb/bagel-db";
// const db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);
// const db = require("../services/db");
// const { collection } = db;
import * as bagelObj from "../services/db.js";
const { db } = bagelObj.default;

// eslint-disable-next-line no-unused-vars
const [Pending, Accepted, Dismissed] = [
  { _id: "c4m8ali23akg00e727bg", value: "Pending" },
  { _id: "c4m8ali23akg00e727cg", value: "Accepted" },
  { _id: "c4m8ali23akg00e727c0", value: "Dismissed" }
];

function andorHandler(operator, v1, v2) {
  if (operator == "or") return v1 || v2;
  if (operator == "and") return v1 && v2;
}

const addAll = async (boardID, companyList) => {
  try {
    const recommendations = db
      .collection("boards")
      .item(boardID)
      .collection("recommendation");

    const { data: getRecs } = await db
      .collection("boards")
      .item(boardID)
      .collection("recommendation")
      .get();

    // return console.log(getRecs[getRecs.length - 1]); // ? last item of recs
    return console.log(companyList[companyList.length - 1]);

    return console.log(
      getRecs[getRecs.length - 1].company,
      getRecs[getRecs.length - 1].status
    );

    companyList.forEach(async company => {
      await recommendations.post({
        ...company,
        company: [
          {
            branchesNestedCollection: [],
            itemRefID: company._id,
            value: company.name
          }
        ],
        status: Pending
      });
    });

    // return console.log({ data: getRecs });
  } catch (error) {
    console.error({ error: error });
  }
};
// eslint-disable-next-line no-unused-vars
const deleteAll = async boardID => {
  try {
    const recommendations = await db
      .collection("boards")
      .item(boardID)
      .collection("recommendation");

    const { data: getRecs } = await db
      .collection("boards")
      .item(boardID)
      .collection("recommendation")
      .get();

    // console.log({ getRecs: getRecs[0] });
    for (let company of getRecs) {
      try {
        const res = await recommendations.item(company._id).delete();
        /*
        const res = await db
          .collection("boards")
          .item(boardID)
          .collection("recommendation")
          .item(company._id)
          .delete();
        */
        console.log({ res });
      } catch (error) {
        console.error({ error: error });
      }
    }
    // return console.log({ recommendations });
  } catch (error) {
    console.error({ error: error });
  }
};

// eslint-disable-next-line no-unused-vars
const generateRecs = async (/* event, */ boardID) => {
  // console.log(await db.collection("boards"));.
  try {
    // return console.log(db);
    // let payload = JSON.parse(event.body);
    // eslint-disable-next-line no-unused-vars

    const payload = { board: boardID /* || "5faab0640301ed25302173e5" */ }; //? DEV VAR

    let { data: board } = await db
      .collection("boards")
      .item(payload.board)
      .projectOn("filters,companies")
      .get();
    console.log("looking for companies...");
    let filters = board.filters;
    // return console.log(filters);
    if (!filters) {
      const error = { statusCode: 404, body: "Filters not set" };
      console.error({ error });
      return { statusCode: 404, body: "Filters not set" };
    }
    let { data: companyList } = await db
      .collection("companies")
      // .perPage(1000)
      .everything()
      .query("status._id", "=", "bpf61ma23akg00e80p10")
      .projectOn("name,remote,branchesNestedCollection,companyType")
      .get();
    companyList = companyList.filter(
      c => !board.companies.find(bc => bc.itemRefID == c._id)
    );

    console.log(`Sorting through ${companyList.length} options`);

    for (let params of filters) {
      if (params.type == "object") {
        companyList = companyList.filter(c => {
          let pass;
          for (let field of params.fields) {
            pass = andorHandler(params.operation, test(c, field), pass);
          }
          return pass;
        });
      }
      companyList = companyList.filter(c => test(c, params));
    }

    console.log(
      `Recommending ${companyList.length} companies: ${companyList
        .map(c => c.name)
        .join("\n")}`
    );
    return companyList.slice(0, 20);
    companyList = companyList.slice(0, 5); // TODO: remove line

    // addAll(payload.board, companyList);
    return;

    //? Email
    //   try {
    //     await axios.post(
    //       "https://jlm.freshboard.city/.netlify/functions/send-email?template=61546bff35b5c3a7b5fe419b",
    //       {
    //         item: { boardName: "", companyCounter: companyList.length }
    //       }
    //     );
    //   } catch (error) {
    //     console.error({ axios: { error } });
    //   }
  } catch (error) {
    console.error(new Error(error));
  }
};

function operator(operation, v1, v2) {
  if (v1 == "true") v1 = true;
  if (v2 == "true") v2 = true;
  if (v1 == "false") v1 = false;
  if (v2 == "false") v2 = false;
  let res = false;
  switch (operation) {
    case "equals":
    case "=":
    case "==":
      res = v1 == v2;
      break;
    case "not-equal":
    case "!=":
      res = v1 != v2;
      break;
    case "contains":
    case "includes":
    case "regex":
      var regex = new RegExp(v2, "gi");
      res = v1 && regex.test(v1);
      break;
  }
  return res;
}

function test(company, field) {
  const splitFields = field.field.split(".");
  if (splitFields.length == 1) {
    return operator(field.operation, company[splitFields[0]], field.value);
  }
  if (splitFields.length == 2 && Array.isArray(company[splitFields[0]])) {
    return !!company[splitFields[0]].find(nested => {
      return operator(field.operation, nested[splitFields[1]], field.value);
    });
  } else if (splitFields.length == 2) {
    return operator(
      field.operation,
      company[splitFields[0]][splitFields[1]],
      field.value
    );
  }
}

export { addAll, deleteAll, generateRecs };
