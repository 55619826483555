<template>
  <div class="newcom-body">
    <div class="top-bar">
      <a class="top-logo bar">Freshboard<strong>City</strong></a>
      <p class="board-name">Jerusalem Tech Jobs</p>
    </div>

    <div class="white-box">
      <img
        v-if="company.logo"
        class="comp-big-logo"
        :src="company.logo.imageURL"
      />
      <div class="comp-big-wrap">
        <div class="comp-big-box">
          <div class="comp-big-opt">
            <a @click="editingCompany = true" class="edit-icon">
              <img src="../assets/images/edit.svg"
            /></a>
            <div class="comp-big-open-opt" :class="{ open: editingCompany }">
              <!-- add open -->
              <a
                @click="editingCompany = false"
                class="blue-small-btn edit save"
                >Save</a
              >
              <a class="blue-small-btn edit delete">Delete</a>
            </div>
          </div>
          <contenteditable
            is="h2"
            v-model="company.name"
            :contenteditable="true"
            class="comp-big-name"
            >{{
          }}</contenteditable>
          <p class="comp-big-tagline">
            {{ company.tagline }}
          </p>
          <p class="comp-big-address">{{ company.address }}</p>
          <p class="comp-big-txt">
            {{ company.description }}
          </p>
          <a :href="company.website" class="comp-big-website" target="_blank">{{
            company.website
          }}</a>
        </div>
        <a @click="positionFocus = true" class="new-position"
          >+ Add a new Position</a
        >
        <div
          class="position-row"
          v-for="position in positions"
          :key="position._id"
        >
          <h4 class="position-row-name">{{ position.position }}</h4>
          <p class="comp-pos">4 Days ago</p>
          <a class="edit-icon position-row-edit">
            <img src="../assets/images/edit.svg"
          /></a>
        </div>

        <popup v-model="positionFocus">
          <div class="newcom-pop">
            <p class="newcom-pop-head">Add New Position</p>
            <form class="newcom-pop-form">
              <input
                type="text"
                name="PositionName"
                class="text-field w-input add-comp"
                placeholder="Position Name*"
                required
              />

              <textarea
                placeholder="Description"
                name="Description"
                class="text-field big w-input"
              >
              </textarea>

              <input
                type="email"
                name="link"
                class="text-field w-input add-comp"
                placeholder="Email*"
                required
              />

              <div class="save-btn-wrap">
                <input
                  type="submit"
                  value="Save"
                  data-wait="Please wait..."
                  class="login-btn w-button"
                />
                <a class="cancel-btn">Cancel</a>
              </div>
            </form>
          </div>
        </popup>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      positionFocus: false,
      editingCompany: false,
      company: {},
      positions: [],
    };
  },
  async mounted() {
    let company = this.$store.getters.getManualCompany(
      this.$route.params.companyID
    );
    let { data: positions } = await this.$store.dispatch("getManualJobs", {
      companyID: this.$route.params.companyID,
    });
    this.positions = positions;
    this.company = company;
  },
};
</script>

<style scoped>
.newcom-body {
  padding: 30px 0;
}
.top-bar {
  background: #f3f3f3;
}
.top-logo {
  color: #047cff;
  text-transform: uppercase;
}
.board-name {
  float: right;
  color: #047cff;
  text-transform: uppercase;
}
.newcom-pop {
  max-width: 580px;
  margin: 0 auto;
  border-radius: 10px;
  background: white;
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}
.newcom-pop-head {
  background-image: linear-gradient(270deg, #047cff, #4be1ff);
  text-align: center;
  color: white;
  padding: 16px;
  margin-bottom: 0;
}
.newcom-pop-form {
  padding: 26px;
  overflow: scroll;
  /* max-height: calc(100vh - 280px); */
}
.website {
  display: flex;
  justify-content: space-between;
}
.website input {
  flex-basis: 49%;
}
.save-wrap {
  width: 180px;
  margin: 0 auto;
}
.input-image {
  position: relative;
  width: 100%;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  transition: all ease 200ms;
  background: white;
}
.blue-small-btn {
  color: white;
  background: #047cff;
  display: inline-block;
  padding: 4px 15px;
  border-radius: 20px;
  margin: 20px;
  font-size: 14px;
  transition: all ease 200ms;
}
.input-image:hover {
  filter: brightness(97%);
}
input[type="file"] {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.white-box {
  max-width: 1170px;
  margin: 0 auto;
  width: 90%;
  position: relative;
}
.comp-big-logo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  position: absolute;
}
.comp-big-wrap {
  margin: 0 130px 0px 150px;
  max-width: 780px;
}
.comp-big-name {
  font-size: 20px;
  font-weight: 600;
}

.comp-big-tagline {
  margin: 0;
}
.comp-big-address {
  color: #95989b;
}
.comp-big-opt {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 92px;
  text-align: right;
}
.comp-big-website {
  color: #95989b;
}
.blue-small-btn:hover {
  filter: brightness(90%);
}
.blue-small-btn:active {
  filter: brightness(80%);
}
.edit-icon {
  border-radius: 100%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-block;
  padding: 4px 0;
  transition: all ease 200ms;
}
.edit-icon:hover {
  background: #ededed;
}
.blue-small-btn.edit {
  width: 90px;
  text-align: center;
  margin: 0;
  background: #95989b;
  margin-bottom: 10px;
  cursor: pointer;
}
.blue-small-btn.edit.save {
  background: #047cff;
}
.blue-small-btn.edit.delete {
  background: white;
  border: red 1px solid;
  color: red;
}
.blue-small-btn.edit.delete:hover {
  background: red;
  color: white;
}
.comp-big-box {
  border-bottom: #ededed 1px solid;
  margin-bottom: 30px;
}
.new-position {
  color: #047cff;
  border: #047cff 1px dashed;
  display: block;
  border-radius: 15px;
  padding: 20px;
  transition: all ease 200ms;
  cursor: pointer;
  background: white;
}
.new-position:hover {
  background: #e0ecfa;
}

.position-row {
  border-bottom: #ededed 1px solid;
  position: relative;

  padding-bottom: 30px;
  margin: 40px 0;
}
.position-row-name {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  padding-right: 30px;
}
.comp-logo {
  position: absolute;
  left: 0;
  border-radius: 10px;
}
.edit-icon.position-row-edit {
  position: absolute;
  right: 0;
  top: 0;
}
.newcom-pop-wrap {
  position: fixed;
  padding: 12vh;
  z-index: -9;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all ease 200ms;
  opacity: 0;
}
.newcom-pop-wrap.open {
  z-index: 99;
  opacity: 1;
}
.newcom-pop-wrap .newcom-pop-form {
  max-height: 90vh;
}
.save-btn-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 80%;
  margin: 0 auto;
}
.save-btn-wrap input {
  flex-basis: 49%;
}
.cancel-btn {
  flex-basis: 49%;
  background: #e0ecfa;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  color: #047cff;
  padding: 12px;
  transition: all ease 200ms;
  cursor: pointer;
}
.cancel-btn:hover {
  filter: brightness(96%);
}
.comp-big-open-opt {
  opacity: 0;
  transition: opacity ease 200ms;
  height: 0;
  overflow: hidden;
}
.comp-big-open-opt.open {
  margin-top: -40px;
  opacity: 1;
  height: auto;
}
@media screen and (max-width: 767px) {
  .board-name {
    display: none;
  }
  .newcom-pop-form {
    padding: 16px;
  }
  .website {
    display: block;
  }
  .save-wrap {
    width: 100%;
  }
  .comp-big-wrap {
    margin: 0px;
    max-width: 780px;
  }
  .comp-big-logo {
    position: relative;
  }
  .comp-big-opt {
    /* position: relative; */
    /* width: 100%; */
    /* text-align: center; */
    top: 10px;
    right: 10px;
  }

  .position-row {
    padding-left: 00px;
    padding-bottom: 30px;
    margin: 40px 0;
  }
  .position-row-name {
    font-size: 18px;
  }
  .comp-logo {
    position: relative;
    margin-bottom: 10px;
  }
}
</style>
