import Vue from "vue";
import Devil from "@bageldb/bagel-db";
// import Devil from "../../../../bagel-db-libraries/bageldb-js/index";

import DATA_LOADING_STATE from "../enums/index";

import Vuex from "vuex";
import router from "../router";
import localforage from "localforage";
import { uniqBy } from "lodash-es";

if (localStorage.getItem("vuex")) localStorage.removeItem("vuex");

Vue.use(Vuex);
Vue.config.productionTip = false;
const projOff =
  "allowLanguageChange,analytics,boardExpansion,colorScheme,companyAddons,filters,locationFilters,locations,logoGallery,tagFilters,tags,recommendation,companies.branchesNestedCollection._createdDate,companies.branchesNestedCollection._lastUpdatedDate,item.branchesNestedCollection._createdDate,item.branchesNestedCollection._lastUpdatedDate";
const apiToken =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6ImFwaS1rZXkiLCJ0eXAiOiJKV1QifQ.eyJvcmdhbml6YXRpb25JRCI6IjIxOTA4OTIwOTciLCJwcm9qZWN0SUQiOiIzNTY4MjAyNTE0IiwiYXVkIjoiaHR0cHM6Ly9hcHAuYmFnZWxkYi5jb20iLCJqdGkiOiIzMmE5MjlkNy01YTY4LTQ1YzItOTgwNC00MGFlY2RjNjVmMWQiLCJpc3MiOiJodHRwczovL2F1dGhlbnRpY2F0aW9uLmJhZ2VsZGIuY28iLCJzdWIiOiIzNTY4MjAyNTE0In0.I0zQjlxCFHPvJwLGPvTpecoDzN2niE_Wvy4kXYLpYcUYUOuW7cPOhJ5su9tSytfk7whYE1J1AW7gpPTSS39AYT4YNz92v7AQQkgbWY-BP6PmqD2kjIE5kpqreaPUk81VGbdjCX-yeXDUNaEsfusUCryM81T_uXAQdc9vubZ9PcLk_1jO3DmKNLoeGGWx3S8aIYIUAiPsMhSjhzxlAaz9Gv_segwTeWN4K6fk6SmDZXL8P8_Emy9bngzYcsaBknsHOi8iwpGUAE4D06TnoVBVQZ3-oGKbIN-SgWgrAdtzsxa3brpjWuJOl-lIIbLlND7wR58-pWBH95aSbD6aDPsLCc4HwXhzBzhZQJaTybg2bbkqus8vlZhTaWQh8N8wxQjd2ELse0BTSvvHj7UTkjRwG6L5GKSXZalJua4bZNokPpK3XfOV4ShfzeKy9kKormDoSw4FmfBFz2Lfk3bjy5Bg1aeXj2mwSjQWrKpFtIl9_-Xyl2ccVL5byW7Xj2KhScAve6EgJXYyXGyfFDnTfDhla7e9if4tVfrOtsGya8R5WLGxZjw61YVxxNhyoBswL7h0rpDh471IXQdfM-e2iF6Lkrpn0ecYBrOGxDBm09OPGOK2wJHQikhYNI1L6dW1IBQM9YuStGvCmvvg2mA0WeCSqqCpq1ER7oPMB7ly1ArQr3s";
const devil = new Devil(apiToken);

const store = new Vuex.Store({
  state: {
    user: null,
    loadingState: null,
    errMsg: "",
    registered: false,
    boards: [],
    selectedBoard: null,
    companies: [],
    boardActiveCompanies: [],
    boardOfflineCompanies: [],
    requestedCompanies: [],
    boardIndustries: [],
    boardLocations: [],
    filteredCompanies: [],
    industries: [],
    locations: [],
    tags: [],
    companiesSaved: false,
    companyRequests: [],
    jobsForAnalytics: [],
    companyFormState: null,
    db: devil,
    localforage,
  },
  getters: {
    getDB: (state) => {
      return state.db;
    },
    boardLanguage: (state) => {
      let lang = "en";
      if (state.selectedBoard.language) {
        if (state.selectedBoard.language._id === "c44hrpi23akg00eqq800")
          lang = "he";
        if (state.selectedBoard.language._id === "c44hrpi23akg00eqq80g")
          lang = "en";
      }
      return lang || "en";
    },
    getManualCompanies: (state) => {
      return uniqBy(state.selectedBoard?.manualCompanies?.map((c) => c.item) || [], "_id");
    },
    getManualCompany: (state) => (id) => {
      // console.log(state.selectedBoard.manualCompanies);
      return state.selectedBoard?.manualCompanies?.find?.((c) => c.itemRefID == id) || [];
    },
    getPromotedJobs: (state) => (companyID) => {
      let promoted = state.selectedBoard?.promotedJobs?.filter?.(
        (j) => j.item.company[0].itemRefID == companyID
      ) || [];
      return promoted.length;
    },
    analytics(state) {
      if (state.selectedBoard?.analytics)
        return state.selectedBoard.analytics.slice(
          state.selectedBoard.analytics.length - 14
        );
      return [];
    },
    getJobs(state) {
      return state.jobsForAnalytics;
    },
    getBoardLocations(state) {
      return state.boardLocations;
    },
    getLocations(state) {
      return state.locations;
    },
    getRequestedCompanies(state) {
      return state.requestedCompanies;
    },
    getSaveState(state) {
      return state.companiesSaved;
    },
    getBoardIndustries(state) {
      return state.boardIndustries;
    },
    getIndustries(state) {
      return state.industries;
    },
    getFilteredCompanies(state) {
      return state.filteredCompanies;
    },
    getLoading(state) {
      return state.loadingState;
    },
    getCompany: (state) => (id) =>
      state.selectedBoard?.companies?.filter?.((c) => c.itemRefID == id) || [],
    getCompanyRequests(state) {
      return state.companyRequests;
    },
    getCompanyFormState(state) {
      return state.companyFormState;
    },
    getErrMsg(state) {
      return state.errMsg;
    },
    getUser(state) {
      return state.user;
    },
    getRegistered(state) {
      return state.registered;
    },
    getBoards(state) {
      return state.boards;
    },
    getSelectedBoard(state) {
      return state.selectedBoard;
    },
    getCompanies(state) {
      return state.selectedBoard?.companies;
    },
    getBoardActiveCompanies(state) {
      return state.boardActiveCompanies;
    },
    getBoardOfflineCompanies(state) {
      return state.boardOfflineCompanies;
    },
    getTags(state) {
      return state.tags;
    },
  },
  mutations: {
    setJobs(state, jobs) {
      state.jobsForAnalytics = jobs;
    },
    setBoardLocations(state, locations) {
      state.boardLocations = locations;
    },
    setSavedState(state, boolVal) {
      state.companiesSaved = boolVal;
    },
    setFilteredCompanies(state, companies) {
      state.filteredCompanies = companies;
    },
    setSignError(state, isCompanyForm) {
      if (!isCompanyForm) state.loadingState = DATA_LOADING_STATE.ERROR;
      else state.companyFormState = DATA_LOADING_STATE.ERROR;
    },
    setSignSuccess(state, isCompanyForm) {
      if (!isCompanyForm) {
        state.loadingState = DATA_LOADING_STATE.CREATED;
      } else state.companyFormState = DATA_LOADING_STATE.CREATED;
    },
    setSignLoading(state, isCompanyForm) {
      if (!isCompanyForm) state.loadingState = DATA_LOADING_STATE.CREATING;
      else state.companyFormState = DATA_LOADING_STATE.CREATING;
    },
    setSignInit(state, isCompanyForm) {
      if (!isCompanyForm) state.loadingState = DATA_LOADING_STATE.INIT;
      else state.companyFormState = DATA_LOADING_STATE.INIT;
    },
    setUser(state, user) {
      state.user = user;
    },
    setErrMsg(state, errmsg) {
      state.errMsg = errmsg;
    },
    setRegistered(state, isRegistered) {
      state.registered = isRegistered;
    },
    setBoards(state, boards) {
      state.boards = boards;
    },
    setSelectedBoard(state, board) {
      state.selectedBoard = board;
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    clearCompanies(state) {
      state.companies = [];
    },
    setBoardActiveCompanies(state, companies) {
      state.boardActiveCompanies = companies;
    },
    setRequestedCompanies(state, companies) {
      state.requestedCompanies = companies;
    },
    setBoardOfflineCompanies(state, companies) {
      state.boardOfflineCompanies = companies;
    },
    setIndustries(state, industries) {
      state.industries = industries;
    },
    setLocations(state, locations) {
      state.locations = locations;
    },
    setBoardIndustries(state, industries) {
      state.boardIndustries = industries;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    ADD_ITEM(state, { key, value }) {
      let item = state;
      for (let k of key.split(".")) {
        item = item[k];
      }
      item.push(value);
    },
    SET_DATA(state, { key, value }) {
      let item = state;
      for (let k of key.split(".")) {
        item = item[k];
      }
      item = value;
    },
    REMOVE_ITEM(state, { key, index }) {
      let keys = key.split(".");
      let st = state;
      for (let k of keys) {
        st = st[k];
        if (keys.indexOf(k) == keys.length - 1) {
          st.splice(index, 1);
        }
      }
    },
  },
  actions: {
    // async getManualCompanies(store) {
    //     return devil.collection("companies").boa(companyID).get()
    // },
    removeManualCompany: async ({ state, dispatch }, companyId) => {
      console.log(companyId);
      await devil
        .collection("boards")
        .item(state.selectedBoard?._id)
        .unset("manualCompanies", companyId);
      await devil.collection("companies").item(companyId).delete();
      dispatch("fetchBoard", state.selectedBoard?._id);

      // if (notOnlyDelete) await devil.collection('boards').item(state.selectedBoard._id).append("offlineCompanies", companyId);
    },
    createDefaultBranch: async (_store, companyID) => {
      try {
        const res = await devil
          .collection("companies")
          .item(companyID)
          .collection("branchesNestedCollection")
          .post({
            branchLocation: [],
            branchName: "HQ",
            rawLocation: "HQ",
          });
        return res;
      } catch (error) {
        throw new Error(error);
      }
    },
    newManualCompany: async ({ getters, dispatch }, newCompany) => {
      newCompany.manualCompany = true;
      let logo;
      if (newCompany.logo) {
        logo = Object.assign({}, newCompany.logo);
        delete newCompany.logo;
      }
      let { data } = await devil.collection("companies").post(newCompany);
      await dispatch("createDefaultBranch", data.id);
      await devil
        .collection("boards")
        .item(getters.getSelectedBoard._id)
        .append("manualCompanies", data.id);
      if (logo)
        await devil
          .collection("companies")
          .item(data.id)
          .uploadImage("logo", logo);
      dispatch("fetchBoard", getters.getSelectedBoard._id);
    },
    async getManualJobs(store, { companyID, num, removeData }) {
      let call = devil
        .collection("jobs")
        .query("company.itemRefID", "=", companyID);
      call = num ? call.perPage(0) : call;
      call = !removeData ? call : call.projectOn("_id");

      return call.get();
    },
    async promoteManual(store, positionID) {
      return devil.collection("jobs").item(positionID).put({ promoted: true });
    },
    async unpromoteManual(store, positionID) {
      return devil.collection("jobs").item(positionID).put({ promoted: false });
    },
    async fetchManualJobs({ getters }) {
      return devil
        .collection("jobs")
        .query("board.itemRefID", "=", getters.getSelectedBoard._id)
        .get();
    },
    async promote({ getters, commit }, job) {
      commit("ADD_ITEM", {
        key: "selectedBoard.promotedJobs",
        value: { itemRefID: job._id },
      });
      await devil
        .collection("boards")
        .item(getters.getSelectedBoard._id)
        .append("promotedJobs", job._id);
    },
    async unpromote({ state, getters, commit }, job) {
      let index = state.selectedBoard?.promotedJobs?.findIndex?.(
        (j) => j.itemRefID == job._id
      );
      if (index === -1) return;
      commit("REMOVE_ITEM", { key: "selectedBoard.promotedJobs", index });
      await devil
        .collection("boards")
        .item(getters.getSelectedBoard._id)
        .unset("promotedJobs", job._id);
    },
    /**
     * return promise
     */
    async getCompanyPositions({ getters }, { companyID, num, removeData }) { // TODO:get 0.3s for company (avrg)
      let comp = getters.getCompany(companyID);
      if (!comp.length) return { data: [] };
      let branches = comp[0].branchesNestedCollection.map((b) => b.itemRefID);
      if (branches.length) {
        let call = devil
          .collection("jobs")
          .query("active", '=', 'true')
          .query(
            "company.branchesNestedCollection.itemRefID",
            "=",
            branches.join(",")
          );

        call = removeData ? call : call.projectOn("_id");
        call = num ? call.perPage(0) : call;

        // .query("company.itemRefID", "=", companyID)
        return call.get();
      }
      return { data: [] };
    },
    async updateBranches({ getters }, companyBranches) {
      let companies = [];
      for (let compID of Object.keys(companyBranches)) {
        let obj = {
          itemRefID: compID,
          branchesNestedCollection: companyBranches[compID].map((b) => {
            return { itemRefID: b };
          }),
        };
        companies.push(obj);
      }
      await devil
        .collection("boards")
        .item(getters.getSelectedBoard._id)
        .put({ companies });
    },
    async fetchCompanyRequests({ state, commit }) {
      try {
        let { data: companyRequests } = await devil
          .collection("companiesRequests")
          .query("board.itemRefID", "=", state.selectedBoard?._id)
          .projectOn("name,link,status.value,companyRef.item.logo,logo")
          .everything()
          .get();
        commit("SET_DATA", { key: "companyRequests", value: companyRequests });
      } catch (err) {
        console.log(err);
      }
    },
    //TODO: prevent duplicate entries and throw error
    async askForNewCompanies({ state, commit }, companyFormList) {
      commit("setSignLoading", true);
      for (const companyForm of companyFormList) {
        let { status, data } = await devil
          .collection("companiesRequests")
          .post({
            name: companyForm.name,
            comment: companyForm.comment,
            link: companyForm.link,
            applicantEmail: state.user.email,
            status: { _id: "buuddb223akg00am66m0" },
            board: [{ itemRefID: state.selectedBoard._id }],
          });
        if (status === 201) {
          console.log("POST success, here is the id of the new item:");
          console.log(data.id);
        } else {
          commit("setSignError", true);
          console.log(data);
        }
      }
    },
    //TODO: prevent duplicate entries and throw error
    async askForNewCompany({ state, commit }, companyForm) {
      commit("setSignLoading", true);
      //? created req with comp ref ID
      let { status, data } = await devil.collection("companiesRequests").post({
        ...companyForm,
        applicantEmail: state.user.email,
        fromAdmin: true,
        status: { _id: "buuddb223akg00am66m0" },
        board: [{ itemRefID: state.selectedBoard._id }],
        companyRef: companyForm.companyRefID
          ? [
            {
              itemRefID: companyForm.companyRefID,
              value: companyForm.name,
              branchesNestedCollection: companyForm.branches.map(
                ({ _id, name }) => ({
                  itemRefID: _id,
                  value: name,
                })
              ),
            },
          ]
          : [],
      });

      if (status === 201) {
        // ? POST success, here is the id of the new item
        return data.id;
      } else {
        commit("setSignError", true);
        console.log(data);
      }
    },
    async changeReqStatus({ state, commit }, { company, isApprove }) {
      commit("setSignLoading", true);
      //? created req with comp ref ID
      let { status, data } = await devil
        .collection("companiesRequests")
        .item(company._id)
        .put({
          fromAdmin: true,
          status: {
            _id: isApprove ? "c7r5dl223aks739l2aig" : "c7r5doi23aks739l2al0",
          },
          board: [{ itemRefID: state.selectedBoard._id }],
          companyRef: company?.companyRef?.[0] || [],
        });

      if (status === 201) {
        // ? PUT success, here is the id of the new item
        return data.id;
      } else {
        commit("setSignError", true);
        console.log(data);
      }
    },
    async findCompanies({ state, commit, dispatch }, searchTerm) {
      commit("setFilteredCompanies", []);
      if (searchTerm === "")
        await dispatch("buildBoardCompaniesList", state.selectedBoard._id);
      let { data } = await devil
        .collection("companies")
        .query("name", "regex", searchTerm)
        .get();
      console.log(
        `here is the filtered companies for this term: ${searchTerm}`
      );
      console.log(data);
      commit("setFilteredCompanies", data);
    },

    async addIndustry({ state, commit }, industryId) {
      let formattedindustries = state.boardIndustries.map((industry) => ({
        itemRefID: industry.itemRefID,
      }));
      formattedindustries.push({ itemRefID: industryId });
      commit("setBoardIndustries", formattedindustries);
      await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .put({ industries: formattedindustries });
    },

    async removeIndustry({ state, commit }, industryId) {
      let formattedindustries = state.boardIndustries.map((industry) => ({
        itemRefID: industry.itemRefID,
      }));
      let filteredIndustries = formattedindustries.filter(
        (indust) => indust.itemRefID != industryId
      );
      commit("setBoardIndustries", filteredIndustries);
      await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .put({ industries: filteredIndustries });
    },

    async removeLocation({ state, commit }, locationId) {
      let formattedLocations = state.boardLocations.map((location) => ({
        itemRefID: location.itemRefID,
      }));
      let filteredLocations = formattedLocations.filter(
        (loc) => loc.itemRefID != locationId
      );
      console.log("after remove, filteredLocations is:");
      console.log(filteredLocations);
      commit("setBoardLocations", filteredLocations);
      await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .put({ locationFilters: filteredLocations });
    },

    async addLocation({ state, commit }, locationId) {
      let formattedLocations = state.boardLocations.map((location) => ({
        itemRefID: location.itemRefID,
      }));
      formattedLocations.push({ itemRefID: locationId });
      console.log("after push, formattedLocations is:");
      console.log(formattedLocations);
      commit("setBoardLocations", formattedLocations);
      await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .put({ locationFilters: formattedLocations });
    },

    // remove a company from the online companies array and add it to the offline companies array
    async pauseCompany({ state }, props) {
      let company = props.company;

      let notOnlyDelete = props.notOnlyDelete;

      if (notOnlyDelete) state.boardOfflineCompanies.push(company);
      let companyId = company._id;
      await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .unset("companies", companyId);
      this.commit("REMOVE_ITEM", {
        key: "selectedBoard.companies",
        index: props.index,
      });

      // if (notOnlyDelete) await devil.collection('boards').item(state.selectedBoard._id).append("offlineCompanies", companyId);
    },

    async playCompany({ state }, props) {
      let company = props.company;
      let index = props.index;
      let notOnlyDelete = props.notOnlyDelete;
      console.log("state.boardOfflineCompanies[index] is:");
      console.log(state.boardOfflineCompanies[index]);
      state.boardOfflineCompanies.splice(index, 1);
      if (notOnlyDelete) state.boardActiveCompanies.push(company);
      let companyId = company._id;
      await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .unset("offlineCompanies", companyId);
      if (notOnlyDelete)
        await devil
          .collection("boards")
          .item(state.selectedBoard._id)
          .append("companies", companyId);
    },

    async deleteCompanyFromOnline({ state }, props) {
      let company = props.company;
      let index = props.index;
      console.log("state.boardOfflineCompanies[index] is:");
      console.log(state.boardOfflineCompanies[index]);
      state.boardOfflineCompanies.splice(index, 1);
      let companyId = company._id;
      await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .unset("offlineCompanies", companyId);
    },

    async deleteCompanyFromOffline({ state }, props) {
      let company = props.company;
      let index = props.index;
      console.log("company is;");
      console.log(company);
      console.log("index is:");
      console.log(index);
      state.boardActiveCompanies.splice(index, 1);
      let companyId = company._id;
      await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .unset("companies", companyId);
    },

    async updateBoard(
      _,
      { disclaimerContentHe, disclaimerContentEn, name, tagline, newLogo, id }
    ) {
      let finalForm = newLogo?.selectedImage
        ? {
          name,
          tagline,
          disclaimerContentHe,
          disclaimerContentEn,
          topLogoGallery: [],
        }
        : { name, tagline, disclaimerContentHe, disclaimerContentEn };
      try {
        let result = await devil.collection("boards").item(id).put(finalForm);
        if (newLogo?.selectedImage) {
          const uploadResult = await devil
            .collection("boards")
            .item(id)
            .uploadImage("topLogoGallery", {
              selectedImage: newLogo.selectedImage,
              // extension: newLogo.selectedImage.name.split(".").pop(),
              altText: newLogo.selectedImage.name,
              fileName: newLogo.selectedImage.name,
            });
          result = { uploadResult, result };
        }
        return result;
      } catch (error) {
        console.warn(error);
      }
    },

    async removeBoard({ state }, boardId) {
      let { data } = await devil
        .collection("owners")
        .item(state.user._id)
        .get();
      console.log("updatedUser:");
      console.log(data);
      let updatedBoardArr = data.board.filter(
        (board) => board.itemRefID != boardId
      );
      console.log("updatedBoardArr:");
      console.log(updatedBoardArr);
      let boardsIdsArr = updatedBoardArr.map((board) => ({
        itemRefID: board.itemRefID,
      }));
      console.log("boardsIdsArr");
      console.log(boardsIdsArr);
      await devil
        .collection("owners")
        .item(state.user._id)
        .put({ board: boardsIdsArr });
    },

    async changeCompanyState({ state }, params) {
      if (params.status) {
        state.boardActiveCompanies.push(params.company);
      } else {
        let i;
        for (i = 0; i < state.boardActiveCompanies.length; i++) {
          if (state.boardActiveCompanies[i]._id === params.company._id) {
            state.boardActiveCompanies.splice(i, 1);
          }
        }
        state.boardOfflineCompanies.push(params.company);
      }
      console.log("active compenies:");
      console.log(state.boardActiveCompanies);
      let fullBoardActiveCompanies = state.boardActiveCompanies.map(
        (company) => ({ itemRefID: company._id })
      );
      let fullBoardOfflineCompanies = state.boardOfflineCompanies.map(
        (company) => ({ itemRefID: company._id })
      );
      let { data } = await devil
        .collection("companies")
        .item(params.company._id)
        .get();
      //state.companies.filter((company) => company._id === params.company);
      console.log("data is:");
      console.log(data);
      let boardsList = data[0] ? data[0].boards : [] || [];
      let fullCompanyBoards = boardsList.map((board) => ({
        itemRefID: board.itemRefID,
      }));

      if (params.status) {
        fullBoardActiveCompanies.push({ itemRefID: params.company._id });
        fullCompanyBoards.push({ itemRefID: params.boardId });
        console.log("fullBoardCompanies");
        console.log(fullBoardActiveCompanies);
        await devil
          .collection("boards")
          .item(params.boardId)
          .put({ companies: fullBoardActiveCompanies });
        await devil
          .collection("companies")
          .item(params.company._id)
          .put({ boards: fullCompanyBoards });
      } else {
        let deletedCompArray = fullBoardOfflineCompanies.filter(
          (company) => company.itemRefID != params.company._id
        );
        let deletedBoardsArray = fullCompanyBoards.filter(
          (board) => board.itemRefID != params.boardId
        );
        await devil
          .collection("boards")
          .item(params.boardId)
          .put({ companies: deletedCompArray });
        await devil
          .collection("companies")
          .item(params.company._id)
          .put({ boards: deletedBoardsArray });
      }
      //await dispatch('buildBoardCompaniesList', params.boardId);
    },

    // async fetchCompanies({ commit }) {
    //     commit('clearCompanies');
    //     let companiesFromDb = await devil.collection('companies')
    //         .query("status", "!=", "bpf62bq23akg00e80p4g")
    //         .perPage(50)
    //         .everything()
    //         .get();
    //     console.log('companies list length is:')
    //     console.log(companiesFromDb.data.length)
    //     commit('setCompanies', companiesFromDb.data);
    // },

    async filterCompaniesByUser({ dispatch, state, commit }) {
      console.log("now fetch companies...");
      await dispatch("fetchCompanies");
      let { data } = await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .get();
      console.log("get board data, concat active and offline companies...");
      // let onlineCompIdLst = data.companies.map((company) => company.itemRefID);
      // let offlineCompIdLst = data.offlineCompanies.map((company) => company.itemRefID);
      let completeCompIdArr = data.companies.concat(data.offlineCompanies);
      let mapping = completeCompIdArr.map((company) => company.itemRefID);
      console.log("filter companies...");
      let filteredCompanies = state.companies.filter(
        (company) => !mapping.includes(company._id)
      );
      console.log(`filtered companies length is ${filteredCompanies.length}`);
      console.log("will setFilteredCompanies...");
      commit("setFilteredCompanies", filteredCompanies);
    },

    async buildBoardCompaniesList({ commit, state, getters }, boardId) {
      commit("setSignLoading");
      commit("SET_DATA",{key:"boardActiveCompanies",value:[]})
      commit("SET_DATA",{key:"boardOfflineCompanies",value:[]})
      commit("SET_DATA",{key:"requestedCompanies",value:[]})
      // state.boardActiveCompanies = [];
      // state.boardOfflineCompanies = [];
      // state.requestedCompanies = [];

      const selectedBoardID = getters['getSelectedBoard']?._id
      const hasBoardAlready = selectedBoardID === boardId;
      console.log({ selectedBoardID });

      let data;

      if (!hasBoardAlready) {
        let res = await devil
        .collection("boards")
        .item(boardId)
        .projectOff(projOff)
        .everything()
        .get();

        data = res.data;
      }

      data = data || getters['getSelectedBoard'];
      console.log('00:',data.JSON);

      let activeCompanies = data.companies.map(
        (companyItem) => companyItem.item
      );
      let offlineCompanies = data.offlineCompanies.map(
        (companyItem) => companyItem.item
      );
      // console.log("here is the companies of this board");
      // console.log(activeCompanies);
      commit("setBoardActiveCompanies", activeCompanies);
      commit("setBoardOfflineCompanies", offlineCompanies);

      let requested = await devil
        .collection("companiesRequests")
        .query("board.itemRefID", "=", boardId)
        .projectOn("name,link,status.value,companyRef.item.logo,logo")
        .everything()
        .get();
      console.log("here is the companiesRequests");
      commit("setRequestedCompanies", requested.data);
      console.log("check", requested.data);
      if (
        state.boardActiveCompanies.length === 0 &&
        state.boardOfflineCompanies.length === 0 &&
        state.requestedCompanies.length === 0
      )
        commit("setSignError");
      else commit("setSignSuccess");
    },

    async buildIndustriesList({ state, commit }) {
      let { data } = await devil.collection("industries").get();
      console.log("selectedBoard.industries is:");
      console.log(state.selectedBoard.industries);
      commit("setBoardIndustries", state.selectedBoard.industries);
      console.log("industries data");
      console.log(data);
      commit("setIndustries", data);
    },

    async buildLocationsList({ state, commit }) {
      let { data } = await devil.collection("locations").get();
      commit("setBoardLocations", state.selectedBoard.locationFilters);
      console.log("lcoations data");
      console.log(data);
      commit("setLocations", data);
    },

    async fetchBoard({ state, commit, getters }, boardId) {
      const selectedBoard = JSON.parse(await state.localforage.getItem(boardId) || '{}');

      const hasBoardAlready = selectedBoard?._id === boardId;
      console.log({ selectedBoardID: selectedBoard?._id });

      let data;

      if (!hasBoardAlready) {
        await state.localforage.removeItem(boardId);

        let { data: boardFromDb } = await devil
          .collection("boards")
          .item(boardId)
          .projectOff(projOff)
          .everything()
          .get();
          await state.localforage.setItem(boardId, JSON.stringify(boardFromDb));
          data = boardFromDb;

          console.log('11:',data.JSON);
        }

        data = data || selectedBoard;

      commit("setSelectedBoard", data);
      await this.dispatch("fetchCompanyRequests");
    },

    async fetchTags({ commit }, boardId) {
      let { data } = await devil
        .collection("boards")
        .item(boardId)
        .collection("tags")
        .get();
      console.log("here is the tags of the board:");
      console.log(data);

      console.log(data.tags);
      commit("setTags", data);
    },

    async updateTags({ state }, { keywords, tagId }) {
      console.log("in updateTags");
      console.log(tagId);
      return devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .collection("tags")
        .item(tagId)
        .put({ keywords: keywords });
      //await dispatch('fetchTags');
    },
    async createTag({ commit, state }, tag) {
      await commit("setSignLoading");
      devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .collection("tags")
        .post({
          tagName: tag.name,
          keywords: tag.keywords,
        })
        .then(() => commit("setSignSuccess"))
        .catch((err) => {
          console.log(err);
          commit("setSignError");
        });
    },

    async removeTag({ state, dispatch }, tagId) {
      let tryDelete = await devil
        .collection("boards")
        .item(state.selectedBoard._id)
        .collection("tags")
        .item(tagId)
        .delete();
      console.log(tryDelete);
      await dispatch("fetchTags", state.selectedBoard._id);
    },

    async fetchBoards({ commit }) {
      commit("setSignLoading");
      setTimeout(async () => {
        try {
          let { data: boards } = await devil
            .collection("boards")
            .projectOn("name,tagline")
            .get();
          commit("setSignSuccess");
          commit("setBoards", boards);
        } catch {
          commit("setSignError");
        }
      }, 3000);
    },

    async signUp({ commit }, form) {
      commit("setSignError", false);
      try {
        let userID = await devil.users().create(form.email, form.password);
        delete form.password;
        var finalForm = {
          name: form.userName,
          email: form.email,
        };
        console.log(devil)
        await devil.collection("owners").item(userID).set(finalForm);
        var { data: fullBagelUser } = await devil
          .collection("owners")
          .item(userID)
          .get();

        commit("setUser", fullBagelUser);
        commit("setSignSuccess");
      } catch (err) {
        let errMsg = err.message
        if (err?.response?.data) {
          errMsg = err.response.data.trim().split(/[\[\]]/g).filter(m => !!m).pop()
        }
        commit("setSignError");
        commit("setErrMsg", errMsg);
        return;
      }
    },
    sendForgotPassword: ({ }, { email }) => devil.users().requestPasswordReset(email),
    async signIn({ commit }, form) {
      commit("setSignLoading");
      try {
        let userID = await devil.users().validate(form.email, form.password);
        var { data: fullBagelUser } = await devil
          .collection("owners")
          .item(userID)
          .projectOff("board")
          // .everything()
          .get();
        commit("setUser", fullBagelUser);
        await this.dispatch("fetchBoards");
        commit("setSignSuccess");
      } catch (err) {

        commit("setSignError");
        commit("setErrMsg", err.message);
        console.log(err.message);
        return;
      }
    },
    async signOut({ commit }) {
      await devil.users().logout();
      commit("setUser", null);
      if (router.currentRoute.name != "signin") {
        router.push({ name: "signin" });
      }
    },
    async fetchUser({ commit }) {
      let userID = await devil.users().getBagelUserID();

      if (userID) {
        try {
          let { data } = await devil
            .collection("owners")
            .item(userID)
            .projectOff("board")
            // .everything()
            .get();
          commit("setUser", data);
          this.dispatch("fetchBoards");
        } catch (err) {
          this.dispatch("signOut");
          // this.dispatch("fetchUser")
        }
      }
    },
  },
});

export default store;
