<template>
  <div v-on-clickout="closeToggle">
    <div class="side-dots" @click="openToggle">⋮</div>
    <div class="drop-side" v-if="open">
      <slot />
    </div>
  </div>
</template>

<script>
import { mixin as clickout } from 'vue-clickout'

export default {
  mixins: [clickout],
  name: "dropdown",
  data() {
    return {
      open: false,
    };
  },
  methods: {
    closeToggle(){
      if (this.open){
        this.open = false
      }
    },
    openToggle() {
      this.open = !this.open;
    },
  },
};
</script>