<template>
  <div class="tabs-parent">
    <div class="tabs-wrapper">
      <v-tabs>
        <v-tab
          v-for="tab of tabs"
          class="menu-tabs"
          :to="'/boards/' + $route.params.id + '/' + tab.path"
        >{{ tab.name }}</v-tab>
      </v-tabs>
    </div>
    <a
      :disabled="getSelectedBoard"
      class="pubbtn"
      :href="
        getSelectedBoard
          ? `https://${getSelectedBoard.domain}.freshboard.city`
          : '#'
      "
      target="_blank"
    >Go To Job Board</a>
    <keep-alive>
      <slot></slot>
    </keep-alive>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: "TabNav",
  computed: {
    ...mapGetters(["getSelectedBoard"])
  },
  props: {
    tabs: {
      type: Array,
      required: true
    }
  }
};
</script>
<style lang="scss">
.tabs-parent .v-tab-exact-active-class {
  border-bottom-color: #047cff !important;
  color: #047cff !important;
}
</style>
<style scoped>
.tabs-wrapper {
  display: inline-block;
}

.menu-tabs {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 8px 20px;
  border-bottom: 2px solid transparent;
  color: #000;
  font-size: 18px;
  text-decoration: none;
}

.router-link-exact-active {
  border-bottom-color: #047cff !important;
  color: #047cff !important;
}

.pubbtn {
  color: #047cff;
  border: none;
  padding: 10px 20px;
  transition: all 0.25s ease-in-out;
}

.pubbtn:hover {
  background-color: #047dff14;
}

@media screen and (max-width: 769px) {
  .tabs-parent {
    text-align: center;
  }

  .pubbtn {
    float: none;
    white-space: nowrap;
    margin: 0px auto 10px;
    display: block;
    text-align: center;
  }

  .menu-tabs {
    margin: 0;
    padding: 8px 10px;
    border-bottom: 2px solid transparent;
    color: #000;
    font-size: 16px;
    text-decoration: none;
  }
}
</style>