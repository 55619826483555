import { render, staticRenderFns } from "./CompanyPage.vue?vue&type=template&id=5a93e0b0&scoped=true&"
var script = {}
import style0 from "./CompanyPage.vue?vue&type=style&index=0&id=5a93e0b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a93e0b0",
  null
  
)

export default component.exports