import Bagel from "@bageldb/bagel-db";
import { token } from "./bageldb.json";

const db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN || token);
const users = arg => db.users(arg);
const collection = arg => db.collection(arg);

// const obj = () => ({ db, users, collection, EQUAL: Bagel.EQUAL })

export default { db, users, collection, EQUAL: Bagel.EQUAL };
