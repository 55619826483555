<template>
  <div @click="$emit('clicked', 'true')">
    <button
      :color="this.$props.color"
      :style="this.$props.addNew ? 'color:#047cff' : ''"
      :class="this.$props.addNew ? 'add-new' : 'single-industry'"
    >
      {{ this.$props.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: "SingleIndustry",
  props: {
    label: String,
    color: String,
    addNew: Boolean,
  },
};
</script>

<style scoped>
.add-new {
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #047cff;
  border-radius: 1rem;
  padding: 2px;
}
.single-industry {
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  margin-right: 6px;
  border-radius: 1rem;
  padding: 2px;
}
.single-industry:hover {
  border-color: #047cff;
}
</style>
