<script>
import checkUrl from "@/utils/checkURL";

const imgRules = (v) =>
  !v || v.size < 10000000 || "Image size should be less than 10 MB!";
const branchesRules = (v) =>
  !v || !!v.length || "You must select at least one branch";
const initialReqState = {
  name: "",
  link: "",
  comment: "",
  companyRefID: "",
  logo: {},
  description: "",
  address: "",
  messageForUser: "",
};
export default {
  name: "CompanyForm",
  props: ["company", "compReqs"],
  data() {
    return {
      valid: true,
      imgRules: [imgRules],
      urlRules: [(v) => !v || !!checkUrl(v) || "You must provide a valid link"],
      branchesRules: [branchesRules],
      passedComp: this.company || null,
      request: initialReqState,
      allCompanies: [],
      loading: false,
      search: null,
      select: null,
      selectedBranches: [],
      debounce: null,
      typing: null,
      feedback: null,
    };
  },
  watch: {
    search(val) {
      this.passedComp = null;
      const trimmedVal = val?.trim();
      if (!trimmedVal) this.loading = false;
      clearTimeout(this.debounce);
      this.loading = true;
      this.debounce = setTimeout(async () => {
        await this.querySelections(trimmedVal);
        this.typing = null;
        this.addSearchVal();
      }, 600);
    },
  },
  methods: {
    resetValidation() {
      this.$refs.form.resetValidation();
      this.request.companyRefID = "";
    },
    addSearchVal() {
      if (this.search && !this.compNameExists) {
        this.$refs.autocomplete.cachedItems = [
          { name: this.search, _id: "", searchedText: true },
          ...this.allCompanies.slice(1),
        ];
      }

      if (this.reqsByName.includes(this.search?.toLowerCase())) {
        this.$refs.autocomplete.cachedItems = [
          ...this.$refs.autocomplete.cachedItems,
        ].filter((c) => c.searchedText !== true);
      }
    },
    resetForm() {
      this.$refs.form.reset();
      this.request = initialReqState;
      this.search = null;
      this.select = null;
    },
    preSubmit() {
      const bool = this.noFile && !this.companyExists;
      if (bool) {
        this.imgRules[0] = true;
      }
      const pass = this.$refs.form.validate();
      if (bool) {
        this.$refs.form.resetValidation();
      }
      this.imgRules[0] = imgRules;
      return pass;
    },
    async sendCompaniesRequest() {
      if (!this.preSubmit()) return;
      // console.log(`this.$refs.branches;`, this.$refs.branches);
      this.feedback = null;

      try {
        await this.$store.dispatch("askForNewCompany", {
          ...this.request,
          companyRefID: this.select,
          branches: this.selectedBranches,
        });
        this.resetForm();
        this.feedback = { success: "Request sent - add another company" };
      } catch (error) {
        console.error(error);
        this.feedback = { error: "Something isn't right" };
      }
    },
    async querySelections(v) {
      this.request = {
        ...this.request,
        name: this.search,
        companyRefID: this.select,
      };

      const res = v?.length
        ? await this.$store.state.db
            .collection("companies")
            .everything()
            .query("name", "regex", v)
            .get()
        : { data: [] };
      this.allCompanies = [...this?.allCompanies, ...res?.data].filter((c) => {
        return !this.reqsById.includes(c?._id);
      });
      this.loading = false;
    },
    checkLength(item) {
      return item?.length;
    },
  },
  computed: {
    noFile() {
      return Array.isArray(this.request.logo);
    },
    notification() {
      return this.request?.messageForUser;
    },
    compNameExists() {
      return this.allCompanies
        .map((c) => c.name?.toLowerCase())
        .includes(this.search?.toLowerCase());
    },
    reqsByName() {
      return this.compReqs?.map((c) =>
        c?.companyRef?.[0]?.item?.name?.toLowerCase()
      );
    },
    reqsById() {
      return this.compReqs?.map((c) => c?.companyRef?.[0]?.item?._id) || [];
    },
    expandedForm() {
      return this.select === "";
    },
    disabled() {
      return (
        !this.request.name || (this.select !== "" && !this.request.companyRefID)
      );
    },
    feedbackVal() {
      return this?.feedback?.success || this?.feedback?.error;
    },
    companyExists() {
      return this.request.companyRefID;
    },
    branchOptions() {
      //? remove any branches that already exist - shouldn't be an option for user to select from
      const branches = this.allCompanies.filter((c) => c._id === this.select);
      return this.company
        ? this.fallBackBranches
        : branches?.[0]?.branchesNestedCollection;
    },
    fallBackBranches() {
      const compBranch =
        this.company?.companyRef?.[0]?.item?.branchesNestedCollection || [];
      return compBranch;
      // .length === 1 ? compBranch : []
    },
  },
  async mounted() {
    if (this.passedComp) {
      // this.select = this.company?._id || this.company?.name;

      await this.querySelections(this.company?.name);
      this.select = this.company._id;
      // this.allCompanies = [...this.allCompanies,this.company]
      this.request = {
        name: this.company?.name,
        link:
          this.company?.link ||
          this.company?.companyRef?.[0]?.item?.website ||
          "",
        comment: this.company?.comment,
        companyRefID: this.company?._id,
      };
      if (this.company) {
        this.selectedBranches = this.company.branches;
      }
    }
  },
};
</script>

<template>
  <div
    class="comp-pop-box"
    :class="{ expanded: expandedForm, expanded2: checkLength(branchOptions) }"
  >
    <p data-v-34dbded8="" class="newcom-pop-head">New Companies</p>
    <div class="prog_wrap">
      <v-progress-linear
        v-if="loading"
        color="#047cff"
        indeterminate
        rounded
        height="6"
        class="progress mb-10"
      />
      <div v-else height="6" />
    </div>
    <v-form
      class="new-company-form"
      id="new-company-form"
      name="new-company-form"
      data-name="new-company-form"
      ref="form"
      v-model="valid"
      @submit.prevent
    >
      <div v-if="notification">
        <v-alert
          border="bottom"
          color="blue"
          outlined
          dismissible
          type="info"
          transition="slide-y-transition"
          text
          class="alert"
        >
          {{ request.messageForUser }}
        </v-alert>
      </div>

      <div style="margin-bottom: 10px" class="reqForm">
        <v-autocomplete
          ref="autocomplete"
          auto-select-first
          cache-items
          :placeholder="passedComp ? passedComp.name : 'Company Name'"
          v-model="select"
          required
          :loading="loading"
          :search-input.sync="search"
          :item-text="'name'"
          :items="allCompanies"
          :class="`autoComplete ${passedComp && 'placeholder'}`"
          :item-value="'_id'"
          no-data-text="Enter Company Name"
          outlined
          @focus="resetValidation"
          :disabled="company"
        >
          <template v-slot:item="{ item }">
            <span v-if="item.searchedText" style="opacity: 0.5">
              {{ item.name }}
              <span style="color: blue">
                {{ item.searchedText && "(+add new company)" }}
              </span>
            </span>
            <span v-else>
              {{ item.name }}
            </span>
          </template>
        </v-autocomplete>

        <v-text-field
          type="text"
          name="link"
          placeholder="Company's career page link"
          v-model="request.link"
          outlined
          required
          :rules="urlRules"
        />
        <v-text-field
          type="text"
          placeholder="Notes"
          v-model="request.comment"
          outlined
        />
        <transition name="slide-fade">
          <div v-if="companyExists && !expandedForm">
            <v-select
              :items="branchOptions"
              v-model="selectedBranches"
              label="Select branches to be added to board"
              :item-text="'rawLocation' || 'address'"
              multiple
              append-icon="mdi-plus"
              no-data-text="No branches exist for this company"
              class="autoComplete mb-6"
              return-object
              name="branches"
              :rules="branchesRules"
              ref="branches"
            >
              <template v-slot:selection="{ item, index }" class="b-select">
                <v-chip v-if="index <= 1">
                  <span>{{ item.rawLocation || item.address }}</span>
                </v-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ selectedBranches.length }} others selected)
                </span>
              </template>
            </v-select>
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="expandedForm">
            <v-file-input
              :rules="imgRules"
              name="files"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Choose File to Upload (max 10mb)"
              v-model="request.logo"
              prepend-icon="mdi-camera"
              label="Logo"
              class="mb-6"
            ></v-file-input>
            <v-text-field
              type="text"
              placeholder="Details"
              v-model="request.description"
              outlined
            />
            <v-text-field
              type="text"
              placeholder="Address"
              v-model="request.address"
              outlined
            />
            <v-text-field
              type="email"
              placeholder="Email for Applications"
              v-model="request.applicationsEmail"
              outlined
            />
          </div>
        </transition>
        <input
          v-model="request.companyRefID"
          type="hidden"
          name="comapnyRefID"
        />
      </div>
      <div class="new-company-btn-wrap">
        <v-btn
          class="login-btn w-button submit-btn new-company-btn"
          color="#047cff"
          :disabled="disabled"
          @click="sendCompaniesRequest()"
        >
          {{ companyExists ? "Submit Request" : "Add New Company" }}
        </v-btn>
      </div>
    </v-form>

    <div
      v-if="!!feedback"
      :style="{
        color: feedback.success ? 'green' : 'red',
        textAlign: 'center',
        margin: '44px 0 0',
      }"
    >
      {{ feedbackVal }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
* {
  transition: all 1s ease-in-out;
}
.alert {
  transition: all 1s ease-in-out !important;
}
.expanded {
  height: 70vh;
}
.expanded2 {
  height: 50vh;
}
.prog_wrap {
  position: relative;
  top: -55px;

  .progress {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.placeholder {
  *,
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #000 !important;
  }
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 50px;
}
.new-company-btn {
  border: 1px solid #e0ecfa;
  background: #e0ecfa;
  color: #047cff;
  border-radius: 30px;
  padding: 4px 20px;
  transition: all 0.2s ease;
  cursor: pointer;
  text-align: center;
  box-shadow: none;
  display: inline-block;
  width: 90px;
  letter-spacing: 0;
  font-weight: 400 !important;
}
.reqForm {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .autoComplete {
    margin: 0px 0;
  }
}
.comp-pop-box {
  min-height: 400px;
  padding: 0;
  overflow: auto;
  position: relative;
  max-height: 90vh;
  height: max-content;
}
.new-company-form {
  margin: 30px;
}
.comp-new-form {
  float: right;
  transition: all 200ms ease;
  color: #047cff;
  cursor: pointer;
  width: 100%;
  padding: 14px;
  background: white;
  border: #047cff solid 1px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.add-comp {
  display: inline-block;
  width: 31%;
  margin-right: 1%;
  margin-left: 1%;
  vertical-align: top;
  padding: 14px;
  font-size: 16px;
  margin-bottom: 0px;
}
.save-comp {
  width: 12%;
  padding-bottom: 9px;
  padding-top: 9px;
}
.submit-btn {
  font-weight: bold;
  align-content: center;
  text-align: center;
  display: inline-block;
  position: absolute;
  bottom: 25px;
  width: 90%;
  right: 5%;
}

.submit-btn.new-company-btn {
  position: relative;
  margin: 0 auto -40px auto;
  width: 100%;
  right: unset;
}
.v-progress-linear {
  width: 90%;
  margin: 50px auto;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
