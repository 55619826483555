<template>
  <div>
    <vue-tags-input
      v-model="tagTxt"
      :tags="tags"
      @tags-changed="addTag($event)"
      :placeholder="'Add keyword'"
      :allow-edit-tags="true"
      class="tags-keywords"
    />
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "singleTag",
  components: { VueTagsInput },
  methods: {
    async addTag(newTags) {
      let newString = newTags.map((t) => t.text).join();
      console.log(newString);
      this.tags = newString.split(",").map((word) => {
        return { text: word };
      });
      await this.$store.dispatch("updateTags", {
        keywords: newString,
        tagId: this.tag._id,
      });
    },
  },
  props: {
    tag: Object,
  },
  data() {
    return {
      tagTxt: "",
      tags: [],
    };
  },
  mounted() {
    this.tags = this.tag.keywords.split(",").map((word) => {
      return { text: word };
    });
  },
};
</script>

<style>
.ti-input {
  border: transparent !important;
  /*padding: 15px !important;*/
  width: 100% !important;
  /*border-radius: 10px;*/
}
.ti-tag {
  border-radius: 10px !important;
  padding: 5px 15px !important;
  background: #047cff !important;
}
.vue-tags-input {
  max-width: unset !important;
}
</style>
