<script>
import { mapGetters } from "vuex";
import loader from "../components/loader.vue";
export default {
  components: { loader },
  data() {
    return {
      compPositions: null,
      jobCount: 0,
    };
  },
  methods: {
    promote(job) {
      this.$store.dispatch("promote", job);
    },
    unpromote(job) {
      this.$store.dispatch("unpromote", job);
    },
  },
  async mounted() {
    let { data } = await this.$store.dispatch("getCompanyPositions", {
      companyID: this.$route.params.companyID,
      num: "0",
      removeData: 1,
    });
    if (!data.length) {
      let { data: compData } = await this.$store.dispatch("getManualJobs", {
        companyID: this.$route.params.companyID,
      });
      data = compData;
    }
    this.compPositions = data;
    console.log({ ...data[0] });
  },
  computed: {
    ...mapGetters(["getCompany", "getSelectedBoard"]),
    company() {
      let c = this.getCompany(this.$route.params.companyID);
      const compData = c?.[0]?.item;
      return compData;
    },
  },
};
</script>

<template>
  <div class="results">
    <div class="_1200">
      <div
        class="comp-wrap"
        v-if="company"
      >
        <div class="company-profile-wrapper">
          <div
            v-if="company.logo"
            :style="{
              'background-image': `url(${company.logo.imageURL})`,
            }"
            class="company-logo"
          ></div>
          <div class="company-txt-wrapper">
            <p class="name-of-job-list">{{ company.name }}</p>
            <span
              v-if="compPositions"
              class="gray-txt"
            >{{ compPositions.length }} Jobs</span>
            <p class="company-bio">{{ company.tagline }}</p>
            <p class="gray-txt">{{ company.address }}</p>
            <p class="company-bio">{{ company.description }}</p>
          </div>
        </div>
        <div class="job-counter"></div>
      </div>
      <div
        v-else
        style="color: red; text-align: center; width: 100%"
      > Sorry, we couldn't find what you're looking for </div>
      <!-- <div class="nothing-found" v-if="!compPositions.length">
        <p>Sorry, no jobs here today...</p>
      </div> -->
      <!-- <hr style="border-color: #26a5ff" /> -->
      <div class="positions-wrap">
        <loader v-if="!compPositions" />
        <div v-else>
          <div
            v-for="position in compPositions"
            :key="position._id"
            class="position"
          >
            {{ position.position }}
            <a
              class="promote promoted"
              v-if="
                getSelectedBoard.promotedJobs.find(
                  (p) => p.itemRefID == position._id
                )
              "
              @click="unpromote(position)"
            >Promoted</a>
            <a
              class="promote"
              v-else
              @click="promote(position)"
            >Promote</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (max-width: 769px) {
  .job-counter {
    display: none;
  }
}

._1200 {
  display: flex;
  justify-content: space-between;
}

.comp-wrap {
  width: 40%;
}

.company-profile-wrapper {
  min-width: 300px;
  text-align: center;
  padding: 0 30px;
  margin-right: 30px;
}

.results {
  padding: 20px;
}

.positions-wrap {
  flex-grow: 1;
}

.position {
  border-bottom: 1px solid #e7e7e7;
  min-height: 70px;
  position: relative;
  padding: 18px 120px 18px 0;
}

.job-counter {
  float: right;
  margin-top: -110px;
  margin-right: 37px;
  font-size: 20px;
  color: grey;
}

.nothing-found {
  text-align: center;
  height: 30vh;
  background-size: contain;
  background-position: 50% 50%;
  margin: auto;
  width: 80%;
  opacity: 0.4;
  max-width: 400px;
  position: relative;
}

.company-logo {
  /* display: inline-block; */
  width: 125px;
  height: 125px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: top;
}

.promote {
  cursor: pointer;
  padding: 0 30px;
  margin-top: 5px;
  background: #f3f3f3;
  transition: all 0.4s;
  margin-top: 16px;
  height: 36px;
  border-radius: 30px;
  margin-bottom: 10px;
  padding: 0 15px;
  line-height: 36px;
  font-weight: 400;
  position: absolute;
  right: 0px;
  top: 0px;
}

.promote:hover {
  background: #26a5ff3c;
}

.promoted:hover,
.promoted {
  background: #26a5ff;
  color: white;
}

.name-of-job-list {
  /* display: inline-block; */
  margin-bottom: 0px;
  padding-right: 20px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.company-txt-wrapper {
  display: inline-block;
  max-width: 700px;
}

.nothing-found p {
  top: 80%;
  position: absolute;
  margin: auto;
  left: 30%;
}
</style>
