var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"small"},[_c('div',{staticClass:"white-box half"},[_vm._v(" New Positions By Date "),_c('line-chart',{attrs:{"options":{
        styles: {
          height: '300px',
        },
        maintainAspectRatio: false,
      },"chart-data":_vm.newPositionsByDate}})],1),_c('div',{staticClass:"white-box half right"},[_vm._v(" Total Positions By Date "),_c('line-chart',{attrs:{"options":{
        styles: {
          height: '300px',
        },
        maintainAspectRatio: false,
      },"chart-data":_vm.totalPositionsByDate}})],1),_c('div',{staticClass:"white-box half"},[_c('doughnut-chart',{attrs:{"chartData":_vm.wordCount,"options":{
        'font-size': '140px',
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }