<template>
  <div
    v-if="value"
    @click="easyClose($event)"
    @keydown.esc="closePopup"
    class="bglpopup show"
    :class="open ? 'bglfull' : ''"
  >
    <div @click="closePopup()" class="close-pop">
      <svg
        width="20"
        height="20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <line x1="20" x2="0" y1="20" y2="00" stroke="white" stroke-width="1" />
        <line x1="0" x2="20" y1="20" y2="0" stroke="white" stroke-width="1" />
      </svg>
    </div>
    <div :style="style()" class="pop-box" :class="open ? 'show' : ''">
      <div v-if="title" class="top-yellow-pop">
        <p>{{ title }}</p>
      </div>
      <slot class="pop-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    width: String,
    type: String,
    value: [Object, String, Boolean],
    clickToClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    style() {
      var style = "";
      if (this.width) style += "width:90%;max-width:" + this.width + ";";
      if (this.type == "image") style += "background-color:transparent;";
      return style;
    },
    easyClose(e) {
      if (e.target.classList.contains("show") && this.clickToClose) {
        this.closePopup();
      }
    },
    closePopup() {
      // console.log('whatttt')
      this.open = false;
      var emitValue = "";
      if (typeof this.value == "boolean") emitValue = false;
      setTimeout(() => this.$emit("input", emitValue), 200);
    },
    create() {
      setTimeout(() => (this.open = true), 10);
    }
  },
  watch: {
    value() {
      if (this.value) {
        setTimeout(() => (this.open = true), 10);
      } else {
        this.open = false;
      }
    },
    open() {
      // console.log('Opened?')
      // console.log(this.open)
    }
  }
};
</script>

<style scoped>
.pop-box {
  padding-bottom: 1px;
}

.pop-box {
  position: relative;
  /*width: 90%;*/
  /*max-width: 664px;*/
  margin: 5vh auto 4vh;
}
/* .close-pop svg{
    position: absolute;
    top: 12px;
    left: 12px;
} */
/* .close-pop {
  z-index: 5;
  position: absolute;
  left: auto;
  top: -22px;
  right: -22px;
  bottom: auto;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background-color: #fff;
  cursor: pointer;
  object-position: 50% 50%;
  background-repeat: no-repeat;
} */

.close-pop {
  z-index: 5;
  position: fixed;
  top: 30px;
  right: 12px;
  bottom: auto;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  cursor: pointer;
  object-position: 50% 50%;
  background-repeat: no-repeat;
}

.bglpopup {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99999;
  display: block;
  background-color: rgba(0, 0, 0, 0);

  transition: all ease 0.2s;
}
.bglfull {
  background-color: rgba(0, 0, 0, 0.75);
}
.bglpopup.show {
  display: block;
  overflow: scroll;
  overflow: hidden;
  height: 100vh;
}

.pop-box {
  position: relative;
  /*width: 90%;*/
  /*max-width: 664px;*/
  /* margin: 16vh auto 20px; */
  /* margin-top: 100px; */
}
.pop-box.show {
  transform: scale(1);
  opacity: 1;
}
.pop-box {
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.2s; /* Animation */
  /*width: 90%;*/
  /*max-width: 664px;*/
  position: relative;
  /* margin: 16vh auto 20px; */
}
@media screen and (max-width: 992px) {
  .close-pop {
    zoom: 0.7;
  }
  .pop-box {
    margin-top: 50px !important;
  }
}
</style>