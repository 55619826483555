<template>
  <div
    class="company-results _1200"
    :class="recommendations.length ? '' : 'cloaked'"
  >
    <h4 class="comp-title">Recommendations for Board</h4>
    <transition-group
      name="list-complete"
      appear
    >
      <div
        v-for="(company, index) in companies.slice(0, 3)"
        :key="company.key"
        :class="`add-row-comp list-complete-item ${index && 'not-first'}`"
      >
        <div
          :style="
            'background-image: url(' +
            (company.logo.imageURL || fallbackImg) +
            ')'
          "
          class="company-logo mid"
        />
        <p class="company-name">{{ company.name }}</p>
        <div
          class="numJobs"
          v-if="company.jobs"
        >
          <span>Jobs: {{ company.jobs }}</span>
        </div>
        <a
          class="info"
          :href="`https://${board.domain}.freshboard.city/company/${company._id}`"
          target="_blank"
        >
          {{/** //? INFO */}} view company info </a>
        <div class="fab-wrap">
          <button
            @click.prevent="removeFromRecommend(company.key, company)"
            class="fab del"
          >
            {{/** //! REMOVE */}} dissmiss </button>
          <button
            @click.prevent="addToBoard(company.key, company)"
            class="fab"
          >
            {{/** //* ADD */}} add to board </button>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { uniqBy } from "lodash-es";
import { mapGetters } from "vuex";
import {
  addAll,
  deleteAll,
  generateRecs,
} from "@/utils/generateRecommendations";
import fallbackImg from "@/assets/images/light-logo.svg";

const status = {
  Pending: { value: "Pending", _id: "c4m8ali23akg00e727bg" },
  Dismissed: { value: "Dismissed", _id: "c4m8ali23akg00e727c0" },
  Accepted: { value: "Accepted", _id: "c4m8ali23akg00e727cg" },
};

export default {
  data: () => ({
    recommendations: [],
    companies: [],
    fallbackImg,
  }),
  props: {
    board: Object,
    updateRecCount: Function,
  },
  methods: {
    processRecommendations(arr) {
      const res = arr
        .filter((c) => c.company.length && c.status.value === "Pending")
        .map((c) => ({ ...c.company[0].item, key: c._id }));
      this.$emit("updateRecCount", this.companies.length);
      return res;
    },

    async getRecs() {
      const recommendations = this.recCollection().everything();

      const { data } = await recommendations.get();

      this.recommendations = uniqBy(data, (x) => x?.company?.[0]?.itemRefID);
      this.companies = this.processRecommendations(this.recommendations);
    },

    async removeFromRecommend(recRefID, company) {
      // change status value to accepted
      await this.recCollection()
        .item(recRefID)
        .put({ status: status.Dismissed }); //TODO: change back to Dismissed
      // remove from companies array
      this.companies = this.companies.filter((c) => c.key !== recRefID);
    },
    async addToBoard(recRefID, company) {
      // change status value to accepted
      await this.recCollection()
        .item(recRefID)
        .put({ status: status.Dismissed }); //TODO: change back to Accepted
      // add to board
      const compExists = this.getCompanies
        .map((c) => c.itemRefID)
        .join(",")
        .includes(company._id);

      !compExists &&
        (await this.boardCollection().append("companies", company._id));

      // remove from companies array
      this.companies = this.companies.filter((c) => c.key !== recRefID);
    },
  },
  watch: {
    companies() {
      return this.processRecommendations(this.recommendations);
    },
  },
  mounted() {
    this.getRecs();
    // generateRecs();
  },
  computed: {
    ...mapGetters({ db: "getDB", getCompanies: "getCompanies" }),
    recCollection() {
      return () => this.boardCollection().collection("recommendation");
    },
    boardCollection() {
      return () => this.db.collection("boards").item(this.$route.params.id);
    },
  },
};
</script>

<style scoped>
.fab {
  all: revert;
  border-color: #0000;
  font: unset;
}

.company-results {
  margin-bottom: 30px;
  padding: 0 20px;
}

.numJobs {
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 12px;
  width: 100%;
  height: 25px;
  top: 0;
  left: 0;
}

.list-complete-item {
  transition: all 300ms;
  display: inline-block;
}

.list-complete-enter,
.list-complete-leave-to

/* .list-complete-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute !important;
}

.add-row-comp {
  width: 30.333%;
  /* max-width: 300px; */
  margin: 0 auto;
  display: inline-block;
  text-align: center;
  border: #047dff98 1px solid;
  padding: 20px 10px 0 10px;
  margin: 10px 1%;
  border-radius: 10px;
  position: relative;
  min-height: 320px;
  vertical-align: top;
}

.add-row-comp .company-name {
  /* display: inline-block; */
  color: black;
  font-weight: 300;
  height: auto;
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.add-row-comp .company-logo {
  margin: 0 10px;
}

.company-logo.mid {
  width: 60px;
  height: 60px;
}

._1200 {
  text-align: center;
}

.fab:hover {
  background: #047cff;
  color: white;
}

.fab {
  background: #047dff14;
  margin: 10px 0 0 0;
  color: #047cff;
  z-index: auto;
  /* position: absolute; */
  /* width: 100%; */
  top: 40%;
  border-radius: 30px;
  right: 0px;
  font-size: 0.9rem;
  line-height: 1;
  padding: 9px 9px;
  flex-grow: 1;
  width: 100%;
}

.fab.del {
  color: #df0404;
  background: #df040430;
}

.fab.del:hover {
  color: white;
  background: #df0404;
}

.fab-wrap {
  position: absolute;
  bottom: 10px;
  display: block;
  width: calc(100% - 20px);
}

.info {
  color: #047cff;
  background: white;
  text-decoration: underline;
  font-size: 14px;
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 100px;
  margin: 0 auto;
}

.fab.info:hover {
  color: white;
  background: #047cff;
}

.cloaked .comp-title {
  display: none;
}

.comp-title {
  margin: 20px 0 20px 15px;
}

.cloaked {
  height: 0px;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

.company-box.mini {
  position: relative;
  /* width: 130px; */
  height: 125px;
  padding: 10px;
  padding-top: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 1px 1px 15px 0 rgba(41, 40, 59, 0.1);
}

.mini .company-logo.small {
  width: 60px;
  height: 60px;
  margin-right: unset;
}

.mini .company-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  margin-top: 2px;
  font-weight: 400;
  font-size: smaller;
}

.company-logo {
  display: inline-block;
  width: 125px;
  height: 125px;
  margin-right: 30px;
  flex: 0 0 auto;
  background-image: url(https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.company-logo.small {
  width: 80px;
  height: 80px;
  background-image: url(https://test.freshboard.city/img/JMg.ee2a40f9.svg);
}

.company-box {
  width: 31.333%;
  height: 300px;
  margin-right: 1%;
  margin-bottom: 30px;
  margin-left: 1%;
  padding: 40px;
  flex: 0 30%;
  background-color: #fff;
  box-shadow: 1px 1px 27px 0 rgba(41, 40, 59, 0.1);
  transition: all 0.2s ease;
  color: #29283b;
  text-decoration: none;
}

.company-box:hover {
  box-shadow: 0 5px 27px 0 rgba(41, 40, 59, 0.3);
  transform: translateY(-5px);
}

.company-name {
  margin-top: 10px;
  margin-bottom: 3px;
  font-family: Poppins, Almoni neue, sans-serif;
  color: #027aff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.company-box {
  min-width: 200px;
  flex-grow: 1;
}

@media screen and (max-width: 991px) {
  .company-box {
    width: 47%;
    margin-right: 1.5%;
    margin-bottom: 20px;
    margin-left: 1.5%;
    min-width: 200px;
  }

  .company-box:hover {
    box-shadow: 0 0 27px 0 rgba(41, 40, 59, 0.1);
    transform: none;
  }

  /* .info {
    bottom: 80px;
  } */
}

@media screen and (max-width: 900px) {
  .not-first {
    display: none;
  }

  .add-row-comp {
    width: 100%;
    max-width: 300px;
  }
}

@media screen and (max-width: 767px) {
  .company-box {
    padding: 20px;
    flex-basis: 47%;
  }

  .add-row-comp {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .not-first {
    display: none;
  }

  .company-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  .company-logo.small {
    margin-right: 0;
    margin-bottom: 0;
  }

  .company-box {
    height: auto;
    text-align: center;
  }
}

@media screen and (max-width: 479px) {
  .company-logo.small {
    margin-left: 0;
  }
}
</style>
