import { render, staticRenderFns } from "./TabNav.vue?vue&type=template&id=647935ba&scoped=true&"
import script from "./TabNav.vue?vue&type=script&lang=js&"
export * from "./TabNav.vue?vue&type=script&lang=js&"
import style0 from "./TabNav.vue?vue&type=style&index=0&id=647935ba&prod&lang=scss&"
import style1 from "./TabNav.vue?vue&type=style&index=1&id=647935ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647935ba",
  null
  
)

export default component.exports