import VueRouter from "vue-router";
import store from "../store/index";

const routes = [
  {
    name: "signin",
    path: "/signin",
    component: () => import("@/views/SignIn"),
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("@/views/SignUp"),
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("@/views/ForgotPassword"),
  },
  
  {
    name: "boards",
    path: "/",
    component: () => import("@/views/Boards"),
  },
  {
    name: "addManualCompany",
    path: "/addManualCompany/:companyID",
    component: () => import("@/views/addManualCompany"),
  },
  {
    name: "singleBoard",
    path: "/boards/:id",
    component: () => import("@/views/SingleBoard"),
    children: [
      {
        name: "CompaniesSelection",
        path: "",
        component: () => import("@/views/CompanyPage"),
        children: [
          {
            name: "CompanySelection",
            path: "",
            component: () => import("@/views/CompaniesSelection"),
          },
          {
            name: "SingleCompany",
            path: "company/:companyID",
            component: () => import("@/views/SingleCompany"),
          },
          {
            name: "ManualCompany",
            path: "manual-company/:companyID",
            component: () => import("@/views/manualCompany"),
          },
        ],
      },
      {
        name: "",
        path: "manual-companeis",
        component: () => import("@/views/ManualCompanies"),
      },
      {
        name: "Tags",
        path: "tags",
        component: () => import("@/views/Tags"),
      },
      {
        name: "GeneralBoardEditing",
        path: "settings",
        component: () => import("@/views/GeneralBoardEditing"),
      },
      {
        name: "Analytics",
        path: "analytics",
        component: () => import("@/views/analytics"),
      },
      {
        name: "Manual Positions",
        path: "manual",
        component: () => import("@/views/Manual"),
      },
      // {
      //   name: "Filters",
      //   path: "filters",
      //   component: () => import("@/views/Filters")
      // }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/signin", "/signup","/forgot-password"];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired) {
    if (!store.getters.getUser) {
      // console.log(store.getters.getUser)
      await store.dispatch("fetchUser");
    }
    const user = store.getters.getUser != null;

    if (!user) {
      next({
        path: "/signin",
      });
    } else {
      next();
    }
  } else {
    if (!store.getters.getUser) {
      await store.dispatch("fetchUser");
    }
    const user = store.getters.getUser != null;
    if (user) {
      return next({ path: "/" });
    }
    next();
  }
});

export default router;
