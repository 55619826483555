<template>
  <div class="body">
    <div class="login-bg">
      <a class="top-logo">
        Freshboard
        <strong>City</strong>
      </a>
      <div class="white-box-login">
        <div class="w-form">
          <form
            @submit.prevent="userSignUp()"
            id="email-form"
            name="email-form"
            data-name="Email Form"
          >
          <h3>Sign Up</h3>
            <input
              type="text"
              class="text-field w-input"
              maxlength="256"
              name="UserName"
              data-name="UserName"
              placeholder="Full Name"
              id="UserName"
              v-model="signUp.userName"
              required
            />
            <input
              type="email"
              class="text-field w-input"
              maxlength="256"
              name="Email"
              data-name="Email"
              placeholder="Email"
              id="Email"
              v-model="signUp.email"
              required
            />
            <input
              type="password"
              class="text-field w-input"
              maxlength="256"
              name="Password"
              data-name="Password"
              placeholder="Password"
              id="Password"
              v-model="signUp.password"
              required
            />
            <input
              type="submit"
              value="Sign Up"
              data-wait="Please wait..."
              class="login-btn w-button"
            />
          </form>
          <div style="text-align: center">
            <loader v-if="this.loading" />
          </div>
          <div class="w-form-fail" v-if="this.signInError">
            <div>{{ this.getErrMsg }}</div>
          </div>
        </div>
        <p class="small-btn-w" style="text-align: center">
          Already have an account?<br /><router-link
            to="signin"
            class="small-btn"
            >Sign In</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "../components/loader";

export default {
  name: "signup",
  components: { loader },
  data() {
    return {
      signUp: {
        email: "",
        userName: "",
        password: "",
      },
      loading: false,
      signInError: false,
    };
  },
  methods: {
    async userSignUp() {
      this.signInError = false;
      let form = this.signUp;
      await this.$store.dispatch("signUp", form);
      this.checkLoading()
    },
      checkLoading() {
      console.log(this.getLoading);
      switch (this.getLoading) {
        case 1:
          this.loading = true;
          break;
        case 2:
          this.loading = false;
          this.$router.push("/");
          break;
        case 3:
          this.signInError = true;
          this.loading = false;
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["getLoading", "getErrMsg", "getUser"]),
  },
  watch: {
    async getUser() {
      if (this.getUser) this.$router.push("/");
    },
  },
};
</script>