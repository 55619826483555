<template>
  <div class="small">
    <div class="white-box half">
      New Positions By Date
      <line-chart
        :options="{
          styles: {
            height: '300px',
          },
          maintainAspectRatio: false,
        }"
        :chart-data="newPositionsByDate"
      ></line-chart>
    </div>
    <div class="white-box half right">
      Total Positions By Date
      <line-chart
        :options="{
          styles: {
            height: '300px',
          },
          maintainAspectRatio: false,
        }"
        :chart-data="totalPositionsByDate"
      ></line-chart>
    </div>
    <div class="white-box half">
      <doughnut-chart
        :chartData="wordCount"
        :options="{
          'font-size': '140px',
        }"
      />
    </div>
    <!-- <div class="white-box quarter">

    </div> -->
  </div>
</template>

<script>
import LineChart from "../charts/lineChart";
import doughnutChart from "../charts/doughnutChart";
// import barChart from "../charts/barChart";
import { mapGetters } from "vuex";

export default {
  components: {
    LineChart,
    doughnutChart,
    // barChart,
  },
  computed: {
    ...mapGetters(["getJobs", "getSelectedBoard", "analytics"]),
  },
  data() {
    return {
      loaded: false,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      daysToCheck: 7,
      newPositionsByDate: {},
      totalPositionsByDate: {},
      wordCount: {},
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData() {
      this.newPositionsByDate = {
        labels: this.analytics.map(
          (d) =>
            `${this.months[new Date(d._createdDate).getMonth()]}-${new Date(
              d._createdDate
            ).getUTCDate()}`
        ),
        datasets: [
          {
            label: "New Positions",
            backgroundColor: "#2baaff",
            data: this.analytics.map((d) => d.newPositions),
          },
        ],
      };
      this.totalPositionsByDate = {
        labels: this.analytics.map(
          (d) =>
            `${this.months[new Date(d._createdDate).getMonth()]}-${new Date(
              d._createdDate
            ).getUTCDate()}`
        ),
        datasets: [
          {
            label: "All Active Positions",
            backgroundColor: "#2baaff",
            data: this.analytics.map((d) => d.totalPositions),
          },
        ],
      };
      let freq = this.analytics[this.analytics.length - 1].frequencyCount;
      this.wordCount = {
        labels: freq.map((f) => f.word),
        datasets: [
          {
            backgroundColor: [
              "#F7BF48",
              "#F58D32",
              "#AE5BA7",
              "#F33700",
              "#25AEE2",
              "#227BC7",
              "#2A337A",
              "#095237",
              "#7FAB0B",
              "#B9D866",
            ],
            data: freq.map((f) => f.frequency),
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
};
</script>

<style>
.half {
  width: 49%;
  display: inline-block;
}
.right {
  float: right;
}
.three-fourth {
  width: 65%;
  display: inline-block;
  margin-right: 2%;
}
.quarter {
  width: 33%;
  height: 100%;
  float: right;
}
.full {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .half {
    width: 100%;
    display: block;
  }
  .right {
    float: unset;
  }
  .three-fourth {
    width: 100%;
    display: block;
    margin-right: 0;
  }
  .quarter {
    width: 100%;
    float: unset;
  }
}
</style>