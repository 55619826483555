<template>
  <div class="results white-box" >
    <div class="_1200">
      <div class="company-profile-wrapper"></div>
      <div class="job-counter"></div>

      <!-- <div class="nothing-found" v-if="!compPositions.length">
        <p>Sorry, no jobs here today...</p>
      </div> -->
      <!-- <hr style="border-color: #26a5ff" /> -->
    <div
            v-for="position in manualPositions"
            :key="position._id"
            
          >
          <img :src="position.logo.imageURL" height="50px" class="logo-manual">
          <div class="company-info">
            <p>{{ position.position }}
              <span class="companyName">
            {{position.companyName}}
              </span>
            </p>
          </div>
          </div>
    </div>
  </div>
</template>


<style scoped>
@media screen and (max-width: 769px) {
  .job-counter {
    display: none;
  }
}
.position {
  border-bottom: 1px solid #26a5ff;
  height: 60px;
  line-height: 60px;
}
.job-counter {
  float: right;
  margin-top: -110px;
  margin-right: 37px;
  font-size: 20px;
  color: grey;
}
.nothing-found {
  text-align: center;
  height: 30vh;
  background-size: contain;
  background-position: 50% 50%;
  margin: auto;
  width: 80%;
  opacity: 0.4;
  max-width: 400px;
  position: relative;
}
.logo-manual{
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.company-info{
  display: inline-block;
}
.companyName{
  color:darkgray;
  font-size: 14px;
}
.promote {
  cursor: pointer;
  padding: 0 30px;
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  margin-top: 5px;

  border: none;
  float: right;
  transition: all 0.4s;
}

.promote:hover {
  background: #26a5ff3c;
}
.promoted:hover,
.promoted {
  background: #26a5ff;
  color: white;
}

.name-of-job-list {
  display: inline-block;
  margin-bottom: 0px;
  padding-right: 20px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.company-txt-wrapper {
  display: inline-block;
  max-width: 700px;
}
.nothing-found p {
  top: 80%;
  position: absolute;
  margin: auto;
  left: 30%;
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      manualPositions: [],
      jobCount: 0,
    };
  },
  methods: {
    promote(job) {
      this.$store.dispatch("promote", job);
    },
    unpromote(job) {
      this.$store.dispatch("unpromote", job);
    },
  },
  async mounted() {
    let { data } = await this.$store.dispatch("fetchManualJobs");
    console.log(data)
    this.manualPositions = data;
  },
  computed: {
    ...mapGetters(["getSelectedBoard"]),
  },
};
</script>