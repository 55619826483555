<template>
  <div class="body">
    <div class="login-bg">
      <a
        href="#"
        class="top-logo"
      > Freshboard <strong>City</strong>
      </a>
      <div class="white-box-login">
        <div class="w-form">
          <form
            @submit.prevent="userSignIn"
            id="email-signin-form"
            name="email-form"
            data-name="Email Form"
          >
            <h3>Sign In</h3>
            <input
              type="text"
              class="text-field w-input"
              maxlength="256"
              name="email"
              data-name="Email"
              placeholder="Email"
              id="UserName"
              v-model="signIn.email"
              required
            />
            <input
              type="password"
              class="text-field w-input"
              maxlength="256"
              name="Password"
              data-name="Password"
              placeholder="Password"
              id="Password"
              v-model="signIn.password"
              required
            />
            <input
              type="submit"
              value="Sign In"
              data-wait="Please wait..."
              class="login-btn w-button"
            />
          </form>
          <div style="text-align: center">
            <loader v-if="this.loading" />
          </div>
          <div
            class="w-form-fail"
            v-if="this.signInError"
          >
            <div>{{ this.getErrMsg }}</div>
          </div>
        </div>
        <router-link to="forgot-password"> Forgot password </router-link>
        <br />
        <br />
        <a class="small-btn-w">Don't have an account yet?</a>
        <router-link
          to="signup"
          class="small-btn"
        >Sign up!</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "../components/loader";

export default {
  name: "signin",
  components: { loader },

  data() {
    return {
      signIn: {
        email: "",
        password: "",
      },
      loading: false,
      signInError: false,
    };
  },
  methods: {
    async userSignIn() {
      this.signInError = false;
      // let form = this.signIn;
      await this.$store.dispatch("signIn", this.signIn);
      this.checkLoading()
    },
    checkLoading() {
      console.log("bbb", this.getLoading);
      switch (this.getLoading) {
        case 1:
          this.loading = true;
          break;
        case 2:
          this.loading = false;
          this.$router.push("/");
          break;
        case 3:
          this.signInError = true;
          this.loading = false;
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["getLoading", "getErrMsg", "getUser"]),
  },
  watch: {
    async getUser() {
      if (this.getUser) this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.small-btn {
  text-align: center;
  font-weight: bold;
  text-decoration: underline solid none;
  display: block;
  transition: all 200ms ease;
  cursor: pointer;
}

.small-btn-w {
  text-align: center;
  display: block;
  color: black;
}

.small-btn:hover {
  text-decoration: underline solid black;
}
</style>
