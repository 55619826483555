<script>
import dropdown from "../components/dropdown.vue";
import { mapGetters } from "vuex";
export default {
  components: { dropdown },
  data() {
    return {
      newComp: '',
      newCompanyDetails: {},
      companies: [],
      manualPositions: [],
      jobCount: 0,
      url: '',
      positionsByComp: {},
    };
  },
  methods: {
    async copyUrl(event, url) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      try {
        await clipboardData.writeText(url);
        alert("Link Copied");
      } catch (error) {
        console.error(error);
        alert("Cannot copy");
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    async removeCompany(companyId) {
      const execute = confirm("I hope you know what you're doing...");
      if (!execute) return;
      await this.$store.dispatch("removeManualCompany", companyId);
    },
    addLogo(event) {
      this.newCompanyDetails.logo = {};
      this.newCompanyDetails.logo.selectedImage = event.target.files[0];
    },
    async submitNewCompany() {
      this.newComp = "Saving...";
      await this.$store.dispatch("newManualCompany", this.newCompanyDetails);
      this.newComp = '';
    },
    promote(job) {
      this.$store.dispatch("promote", job);
    },
    unpromote(job) {
      this.$store.dispatch("unpromote", job);
    },
    async calcPositionNums() {
      this.$nextTick(async () => {
        for (const comp of this.getManualCompanies) {
          let res = await this.$store.dispatch("getManualJobs", {
            companyID: comp._id,
            num: "0",
            removeData: 1,
          });

          let count = +res?.headers?.["item-count"] || 0;

          // temp.push({ [comp.itemRefID]: compData?.length });
          this.positionsByComp = {
            ...this.positionsByComp,
            [comp._id]: count,
          };
        }
      });
    },
  },
  async mounted() {
    // let { data: } = await this.$store.dispatch("fetchManualJobs");
    // console.log(data);
    // this.manualPositions = data;
    this.$nextTick(async () => {
      await this.calcPositionNums();
    });
  },
  computed: {
    ...mapGetters(["getManualCompanies", "getSelectedBoard", "boardLanguage"]),
    positionCount() {
      return this.positionsByComp;
    },
  },
  watch: {
    async getManualCompanies() {
      await this.calcPositionNums();
    },
  },
};
</script>

<template>
  <div class="results white-box">
    <div class="_1200">
      <div class="company-profile-wrapper"></div>
      <div class="job-counter"></div>
      <div v-for="company in getManualCompanies" :key="company._id">
        <div class="comp-row">
          <router-link
            :to="
              '/boards/' +
              getSelectedBoard._id +
              '/manual-company/' +
              company._id
            "
          >
            <img
              v-bind:src="company.logo.imageURL"
              v-if="company.logo && company.logo.imageURL"
              class="comp-logo"
            />
            <p class="comp-name">{{ company.name }}</p>
          </router-link>
          <span
            v-if="positionCount[company._id] > -1"
            class="comp-pos positionCount"
          >
            <span> | </span> {{ positionCount[company._id] }} Jobs
            <span> | </span>
          </span>
          <div class="actions">
            <div>
              <span class="comp-copy-txt">View Company</span>
              <router-link
                :to="`//${getSelectedBoard.domain}.freshboard.city/board/${company._id}?lang=${boardLanguage}`"
                class="comp-copy"
                target="_blank"
              >
                <span class="comp-copy-fix"></span>
              </router-link>
              <button
                class="comp-copy"
                @click="
                  copyUrl(
                    $event,
                    `https://${getSelectedBoard.domain}.freshboard.city/board/${company._id}?lang=${boardLanguage}`
                  )
                "
              >
                <span></span>
              </button>
            </div>
            <div class="comp-copy-line"></div>
            <div>
              <span class="comp-copy-txt">Edit Company</span>
              <a
                :href="`//${getSelectedBoard.domain}.freshboard.city/board/${company._id}?edit=true&lang=${boardLanguage}`"
                class="comp-copy edit"
                target="_blank"
              >
                <span class="comp-copy-fix"></span>
              </a>
              <button
                class="comp-copy edit"
                @click="
                  copyUrl(
                    $event,
                    `https://${getSelectedBoard.domain}.freshboard.city/board/${company._id}?edit=true&lang=${boardLanguage}`
                  )
                "
              >
                <span></span>
              </button>
            </div>
          </div>
          <dropdown class="comp-btn-wrap">
            <div class="comp-pause delete" @click="removeCompany(company._id)">
              Remove
            </div>
          </dropdown>
        </div>
      </div>
      <a @click="newComp = 'Save Company'" class="new-position-btn"
        >+ New Manual Company</a
      >
      <!-- <div v-for="company in getManualCompanies" :key="company._id">
        <img :src="company.logo.imageURL" height="50px" class="logo-manual" />
        <div class="company-info">
          <p>
            {{ company.name }}
            <span class="companyName">
              {{ company.tagline }}
            </span>
          </p>
        </div>
      </div> -->
    </div>

    <popup v-model="newComp">
      <div class="newcom-pop">
        <p class="newcom-pop-head">Add New Company</p>
        <form class="newcom-pop-form" @submit.prevent="submitNewCompany">
          <input
            type="text"
            name="name"
            v-model="newCompanyDetails.name"
            class="text-field w-input add-comp"
            placeholder="Company Name*"
            required
          />
          <input
            type="text"
            name="Tagline"
            v-model="newCompanyDetails.tagline"
            class="text-field w-input add-comp"
            placeholder="Tagline"
          />
          <input
            type="email"
            name="application email"
            class="text-field w-input add-comp"
            v-model="newCompanyDetails.applicationsEmail"
            placeholder="Email for Applications*"
            required
          />
          <input
            type="text"
            name="Address"
            v-model="newCompanyDetails.address"
            class="text-field w-input add-comp"
            placeholder="Address*"
            required
          />
          Logo
          <div class="input-image">
            <div class="new-logo-up">
              <img v-if="url" :src="url" alt="your image" />
              <div v-else>
                Add an Image
                <span><br />Choose File to Upload<br />(max 10mb)</span>
              </div>
              <input
                accept="image"
                class=""
                fileupload=""
                type="file"
                @input="addLogo"
                @change="onFileChange"
              />
            </div>
          </div>
          <textarea
            v-model="newCompanyDetails.description"
            placeholder="Description"
            name="Description"
            class="text-field big w-input"
          >
          </textarea>
          <div class="website">
            <input
              v-model="newCompanyDetails.website"
              type="text"
              name="WebsiteHomepage"
              class="text-field w-input add-comp"
              placeholder="Website Homepage"
            />
            <input
              type="text"
              v-model="newCompanyDetails.jobBoard"
              name="WebsiteJobsPage"
              class="text-field w-input add-comp"
              placeholder="Website Jobs Page"
            />
          </div>
          <div class="save-wrap">
            <input
              type="submit"
              :value="newComp"
              data-wait="Please wait..."
              class="login-btn w-button"
            />
          </div>
        </form>
      </div>
    </popup>
  </div>
</template>

<style scoped>
.numJobs {
  margin: 0 10px;
}
.comp-logo {
  box-shadow: 0 0 20px 0px #0002;
  margin: 0px 20px 0px 0px;
  position: absolute;
  left: 0;
  top: 10px;
}
.comp-copy-line {
  margin: 3px 10px;
  background: black;
  width: 1px;
  height: 22px;
  vertical-align: top;
}
.comp-copy {
  font-family: "bagelfont";
  font-size: 12px;
  background: #047cff;
  text-align: center;
  color: white;
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 100%;
  vertical-align: top;
  display: inline-block;
  margin-left: 6px;
  transition: ease 200ms all;
}
.comp-copy.edit {
  background: #d0d0d0;
  color: black;
}
.comp-copy:hover {
  filter: brightness(90%);
}
.comp-pause span {
  font-family: "bagelfont";
  padding: 0 0 0 5px;
  font-size: 12px;
  opacity: 0.5;
  position: relative;
}

.newcom-pop-head {
  background-image: linear-gradient(270deg, #047cff, #4be1ff);
  text-align: center;
  color: white;
  padding: 16px;
  margin-bottom: 0;
}
.comp-name {
  color: black;
  margin: 10px 0 20px 66px;
  max-width: calc(100% - 500px);
}
.comp-row {
  position: relative;
  padding: 10px 0 0 0;
  min-height: 80px;
}
.new-position-btn {
  color: #047cff;
  border: #047cff 1px dashed;
  display: block;
  border-radius: 15px;
  padding: 20px;
  transition: all ease 200ms;
  cursor: pointer;
  background: white;
  text-align: center;
  margin-top: 20px;
}
.new-position-btn:hover {
  background: #e0ecfa;
}
.comp-pause {
  display: inline-block;
  min-width: 100px;
  height: 40px;
  margin-left: 10px;
  padding: 0 10px;
  line-height: 38px;
  border-radius: 10px;
  transition: all 200ms ease;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
  background: white;
  border: 1px solid #047cff;
  color: #047cff;
}
.comp-pause.delete {
  border: 1px solid #df0404;
  color: #df0404;
}
.comp-pause.delete:hover {
  background: #df0404;
}
.comp-pause:hover {
  background: #047cff;
  color: white;
  filter: brightness(100%);
}
.actions {
  position: absolute;
  left: auto;
  right: 30px;
  top: 20px;
  text-align: right;
  /* width: 100%;
  display: flex;
  justify-content: center; */
}
.actions > div {
  display: inline-block;
  margin-bottom: 10px;
}
.position {
  border-bottom: 1px solid #26a5ff;
  height: 60px;
  line-height: 60px;
}
.job-counter {
  float: right;
  margin-top: -110px;
  margin-right: 37px;
  font-size: 20px;
  color: grey;
}
.nothing-found {
  text-align: center;
  height: 30vh;
  background-size: contain;
  background-position: 50% 50%;
  margin: auto;
  width: 80%;
  opacity: 0.4;
  max-width: 400px;
  position: relative;
}
.logo-manual {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.company-info {
  display: inline-block;
}
.companyName {
  color: darkgray;
  font-size: 14px;
}
.promote {
  cursor: pointer;
  padding: 0 30px;
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  margin-top: 5px;

  border: none;
  float: right;
  transition: all 0.4s;
}

.promote:hover {
  background: #26a5ff3c;
}
.promoted:hover,
.promoted {
  background: #26a5ff;
  color: white;
}

.name-of-job-list {
  display: inline-block;
  margin-bottom: 0px;
  padding-right: 20px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.company-txt-wrapper {
  display: inline-block;
  max-width: 700px;
}
.nothing-found p {
  top: 80%;
  position: absolute;
  margin: auto;
  left: 30%;
}
.newcom-pop {
  max-width: 580px;
  margin: 0 auto;
  border-radius: 10px;
  background: white;
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}
.newcom-pop form {
  padding: 40px;
}
@media screen and (max-width: 991px) {
  .comp-btn-wrap {
    top: 0px;
  }
  .comp-name {
    max-width: 90%;
  }
  .actions {
    position: relative;
    top: 0px;
    text-align: center;
    right: 0;
    margin: 20px auto;
  }
  .comp-logo {
    position: relative;
    margin: 0px auto 20px;
  }
  .comp-name {
    margin: 0;
  }
  .comp-row {
    padding-left: 0px;
  }
  .comp-pause {
    display: inline-block;
    min-width: 82px;
    height: 34px;
    margin-left: 10px;
    line-height: 33px;
    margin-top: 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 769px) {
  .job-counter {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .comp-copy-txt {
    /* line-height: 1.2; */
    display: block;
  }
  .comp-copy-line {
    margin: 1px 20px;
    background: black;
    width: 1px;
    height: 77px;
    vertical-align: top;
  }
  .comp-copy {
    font-size: 12px;
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 6px;
  }
}
</style>
