<template>
  <div>
    <div class="top-bar w-clearfix">
      <router-link
        class="top-logo bar"
        to="/"
      > Freshboard <strong>City</strong>
      </router-link>
      <div class="profile">
        <router-link
          class="name"
          to="/"
        >{{ getSelectedBoard ? getSelectedBoard.name : "Boards"
        }}</router-link>
        <p class="name small-btn">{{ getUser.name }}</p>
        <p
          @click="signOut()"
          style="font-weight: bold"
          class="name small-btn"
        > Sign Out </p>
      </div>
    </div>
    <TabNav
      class="_1170"
      :tabs="tabs"
    >
      <router-view
        v-if="getSelectedBoard"
        v-slot="{ Component, route: r }"
      >
        <KeepAlive>
          <component
            :is="Component"
            :key="r.name"
          />
        </KeepAlive>
      </router-view>
      <div
        v-else
        class="loader-design"
      >
        <loader />
      </div>
    </TabNav>
  </div>
</template>

<script>
import loader from "@/components/loader.vue";
import { mapGetters } from "vuex";
import TabNav from "@/components/TabNav.vue";
import { isEqual } from "lodash";
const projOff =
  "allowLanguageChange,analytics,boardExpansion,colorScheme,companyAddons,filters,locationFilters,locations,logoGallery,tagFilters,tags,recommendation,companies.branchesNestedCollection._createdDate,companies.branchesNestedCollection._lastUpdatedDate,item.branchesNestedCollection._createdDate,item.branchesNestedCollection._lastUpdatedDate";

export default {
  name: "SingleBoard",
  components: { TabNav, loader },

  computed: {
    ...mapGetters(["getSelectedBoard", "getUser"]),
    tabs() {
      let t = [
        { name: "Companies", path: "" },
        // { name: "Manual Companies", path: "/manual-companeis" },
      ];
      // if (
      //   process.env.NODE_ENV == "development" ||
      //   this.getSelectedBoard._id == "6038d933fb67f4ea867601fb"
      // )
      t.push({ name: "Manual Companies", path: "manual-companeis" });
      // t.push({ name: "Analytics", path: "analytics" });
      t.push({ name: "Settings", path: "settings" });

      return t;
    },
  },
  methods: {
    signOut() {
      this.$store.dispatch("signOut");
    },
    async clearLastBoard(id) {
      const board = await this.getSelectedBoard;
      if (board?._id !== id) this.$store.commit("setSelectedBoard", null);
    },
  },
  async mounted() {
    const boardId = this.$route.params.id;
    await this.clearLastBoard(boardId);
    await this.$nextTick(async () => {
      const selectedBoard = await this.getSelectedBoard;
      if (selectedBoard?._id) return;
      await this.$store.dispatch("fetchBoard", boardId);
    });
    setTimeout(async () => {
      const localSelectedBoard = JSON.parse(await this.$localforage.getItem(boardId) || '{}');
      let { data: boardFromDb } = await this.$store.state.db
        .collection("boards")
        .item(boardId)
        .projectOff(projOff)
        .everything()
        .get();
      // console.log({ boardFromDb, localSelectedBoard, isEqual });
      if (!isEqual(boardFromDb, localSelectedBoard)) {
        // TODO: alert user that there is updated data, and ask if they want to reload
        const shouldUpdate = confirm('There is updated data, do you want to update the current board?')
        if (!shouldUpdate) return;
        await this.$localforage.setItem(boardId, JSON.stringify(boardFromDb));
        await this.$store.dispatch("fetchBoard", boardId);
      }
    }, 2000);
  },
};
</script>

<style scoped>
.top-bar {
  /* display: flex;
  align-items: flex-end; */
}

.flex-item {
  flex-grow: 1;
}

.small-btn {
  text-align: center;
  font-weight: bold;
  /*text-decoration: underline;*/
  display: inline-block;
  transition: all 200ms ease;
  cursor: pointer;
  border-left: 2px solid white;
  padding-left: 10px;
  line-height: 20px;
}

.small-btn:hover {
  text-decoration: underline solid white;
}

.loader-design {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 769px) {
  .small-btn {
    font-size: 14px;
    border-left: 0;
  }
}
</style>
