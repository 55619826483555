<template>
  <div>
    <div class="top-bar w-clearfix">
      <a class="top-logo bar"> Freshboard <strong>City</strong>
      </a>
      <div
        v-if="getUser"
        class="profile"
      >
        <router-link
          class="name"
          to="/"
        >Boards</router-link>
        <p class="name small-btn">{{ getUser.name }}</p>
        <p
          @click="signOut()"
          style="font-weight: bold"
          class="name small-btn"
        > Sign Out </p>
      </div>
    </div>
    <div class="_1170">
      <h1 class="main-head">Your Freshboards</h1>
      <div
        v-if="getLoading === 2 && getBoards.length == 0"
        class="small-white-box-empty"
      >
        <div class="board-name-empty"> No boards have been added to your account yet, please check in soon... <br /> for any questions please contact: roy@freshboard.city </div>
      </div>
      <div v-else>
        <div v-if="getUser && getBoards.length > 0">
          <div
            class="boards-wrapper"
            v-for="board in getBoards"
            :key="board._id"
          >
            <router-link :to="`/boards/${board._id}`">
              <div class="small-white-box">
                <p class="board-name">{{ board.name }}</p>
                <p>
                  {{ board.tagline }}
                  <br />
                </p>
                <p class="date">{{ lastUpdatedDate(board) }}</p>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>
          <p style="align-content: center; text-align: center; margin-top: 30px"> We're loading your boards... </p>
          <Loader style="
              margin-left: auto;
              margin-right: auto;
              display: block;
              transform: scale(0.7);
            " />
        </div>
      </div>
    </div>
    <!-- <router-view v-slot="{ Component, route: r }">
      <KeepAlive>
        <component :is="Component" :key="r.name" />
      </KeepAlive>
    </router-view> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "../components/loader";
export default {
  name: "boards",
  components: { Loader },
  computed: {
    ...mapGetters(["getUser", "getBoards", "getLoading", "getSelectedBoard"]),
  },
  methods: {
    signOut() {
      this.$store.dispatch("signOut");
    },
    lastUpdatedDate(board) {
      if (board._lastUpdatedDate) {
        let date = new Date(board._lastUpdatedDate);
        return `last updated  |  ${date.getDate()}.${date.getMonth() + 1
          }.${date.getFullYear()}`;
      }
      return "";
    },
  },
  watch: {
    getLoading() {
      console.log("get loading is:");
      console.log(this.getLoading);
    },
  },
};
</script>
