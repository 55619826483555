<template>
  <div class="body">
    <div class="login-bg">
      <a href="#" class="top-logo">
        Freshboard
        <strong>City</strong>
      </a>
      <div class="white-box-login">
        <div class="w-form">
          <form
            @submit.prevent="sendForgotPassword"
            id="email-signin-form"
            name="email-form"
            data-name="Email Form"
            v-if="formStatus != 'sent'"
          >
          <h3>Forgot Password</h3>
            <input
              type="text"
              class="text-field w-input"
              maxlength="256"
              name="email"
              data-name="Email"
              placeholder="Email"
              id="UserName"
              v-model="form.email"
              required
            />
          
            <input
              type="submit"
              value="Send Email"
              data-wait="Please wait..."
              class="login-btn w-button"
            />
          </form>
          <div v-if="formStatus === 'sent'">
          <h1>Check your email for the next step</h1>
          <p>You will only receive an email if you have an account</p>
          </div>
          <div style="text-align: center">
            <loader v-if="this.loading" />
          </div>
          <div class="w-form-fail" v-if="this.signInError">
            <div>{{ this.getErrMsg }}</div>
          </div>
        </div>
        <router-link to="signin">
          Back to Login
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "../components/loader";

export default {
  name: "signin",
  components: { loader },

  data() {
    return {
      formStatus:"",
      form: {
        email: "",
      },
      loading: false,
      signInError: false,
    };
  },
  methods: {
    async sendForgotPassword() {
      this.signInError = false;
      // let form = this.signIn;
      try {
        
        await this.$store.dispatch("sendForgotPassword", this.form);
      } catch(err){console.log(err)}
        this.formStatus = "sent"
    },
  },
  computed: {
    ...mapGetters(["getLoading", "getErrMsg", "getUser"]),
  },
  watch: {
    async getLoading() {
      console.log(this.getLoading);
      switch (this.getLoading) {
        case 1:
          this.loading = true;
          break;
        case 2:
          this.loading = false;
          await this.$router.push({ path: "/" });
          break;
        case 3:
          this.signInError = true;
          this.loading = false;
          break;
      }
    },
    async getUser() {
      if (this.getUser) this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.small-btn {
  text-align: center;
  font-weight: bold;
  text-decoration: underline solid none;
  display: block;
  transition: all 200ms ease;
  cursor: pointer;
}
.small-btn-w {
  text-align: center;
  display: block;
  color: black;
}
.small-btn:hover {
  text-decoration: underline solid black;
}
</style>
