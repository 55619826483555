<script>
import { mapGetters } from "vuex";
import dropdown from "../components/dropdown.vue";
import loader from "../components/loader.vue";
export default {
  components: { loader, dropdown },
  data() {
    return {
      compPositions: null,
      jobCount: 0,
    };
  },
  methods: {
    async promote(job) {
      await this.$store.dispatch("promoteManual", job._id);
      job.promoted = true;
    },
    async unpromote(job) {
      await this.$store.dispatch("unpromoteManual", job._id);
      job.promoted = false;
    },
  },
  async mounted() {
    let { data } = await this.$store.dispatch("getManualJobs", {
      companyID: this.$route.params.companyID,
    });
    this.compPositions = data;
  },
  computed: {
    ...mapGetters(["getManualCompany", "getSelectedBoard", "boardLanguage"]),
    company() {
      let c = this.getManualCompany(this.$route.params.companyID);
      return c?.item;
    },
  },
};
</script>

<template>
  <div class="results">
    <div class="_1200">
      <div class="comp-wrap">
        <div v-if="company" class="company-profile-wrapper">
          <dropdown class="comp-btn-wrap">
            <a
              :href="`https://${getSelectedBoard.domain}.freshboard.city/board/${company._id}?lang=${boardLanguage}`"
              class="comp-pause"
              target="_blank"
            >
              View Board <span></span>
            </a>
            <a
              :href="`https://${getSelectedBoard.domain}.freshboard.city/board/${company._id}?edit=true&lang=${boardLanguage}`"
              class="comp-pause"
              target="_blank"
            >
              Edit Board <span></span>
            </a>
            <div class="comp-pause delete" @click="removeCompany(company._id)">
              Remove
            </div>
          </dropdown>
          <div
            v-if="company.logo"
            :style="{
              'background-image': `url(${company.logo.imageURL})`,
            }"
            class="company-logo"
          ></div>
          <div class="company-txt-wrapper">
            <p class="name-of-job-list">{{ company.name }}</p>
            <span v-if="compPositions" class="gray-txt"
              >{{ compPositions.length }} Jobs</span
            >
            <p class="company-bio">{{ company.tagline }}</p>
            <p class="gray-txt">{{ company.address }}</p>
            <p class="company-bio">{{ company.description }}</p>
          </div>
        </div>
        <div class="job-counter"></div>
      </div>
      <!-- <div class="nothing-found" v-if="!compPositions.length">
        <p>Sorry, no jobs here today...</p>
      </div> -->
      <!-- <hr style="border-color: #26a5ff" /> -->
      <div class="positions-wrap">
        <loader v-if="!compPositions" />
        <div v-else>
          <div
            v-for="position in compPositions"
            :key="position._id"
            class="position"
          >
            {{ position.position }}
            <a
              class="promote promoted"
              v-if="position.promoted"
              @click="unpromote(position)"
              >Promoted</a
            >
            <a class="promote" v-else @click="promote(position)">Promote</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.comp-pause span {
  font-family: "bagelfont";
  padding: 5px;
  font-size: 12px;
  opacity: 0.3;
  position: relative;
  right: -4px;
}
.comp-btn-wrap {
  top: -10px;
}
._1200 {
  display: flex;
  justify-content: space-between;
}
.comp-wrap {
  /* width: 40%; */
  width: 300px;
}
.company-profile-wrapper {
  /* min-width: 300px; */
  text-align: left;
  /* padding: 0 30px; */
  margin-right: 30px;
}
.results {
  padding: 20px;
  position: relative;
}
.positions-wrap {
  flex-grow: 1;
  margin-right: 20px;
}
.position {
  border-bottom: 1px solid #e7e7e7;
  min-height: 70px;
  position: relative;
  padding: 18px 120px 18px 0;
}
.job-counter {
  float: right;
  margin-top: -110px;
  margin-right: 37px;
  font-size: 20px;
  color: grey;
}
.nothing-found {
  text-align: center;
  height: 30vh;
  background-size: contain;
  background-position: 50% 50%;
  margin: auto;
  width: 80%;
  opacity: 0.4;
  max-width: 400px;
  position: relative;
}
.company-logo {
  /* display: inline-block; */
  width: 125px;
  height: 125px;
  /* margin-right: auto;
  margin-left: auto; */
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: top;
}
.promote {
  cursor: pointer;
  padding: 0 30px;
  margin-top: 5px;
  background: #f3f3f3;
  transition: all 0.4s;
  margin-top: 16px;
  height: 36px;
  border-radius: 30px;
  margin-bottom: 10px;
  padding: 0 15px;
  line-height: 36px;
  font-weight: 400;
  position: absolute;
  right: 0px;
  top: 0px;
}

.promote:hover {
  background: #26a5ff3c;
}
.promoted:hover,
.promoted {
  background: #26a5ff;
  color: white;
}

.name-of-job-list {
  /* display: inline-block; */
  margin-bottom: 0px;
  padding-right: 20px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.company-txt-wrapper {
  display: inline-block;
  max-width: 700px;
}
.nothing-found p {
  top: 80%;
  position: absolute;
  margin: auto;
  left: 30%;
}
@media screen and (max-width: 769px) {
  .results ._1200 {
    display: block;
  }
  .job-counter {
    display: none;
  }
  .company-profile-wrapper {
    display: block;
    text-align: center;
    margin-right: 0px;
  }
  .comp-wrap {
    width: 100%;
  }
  .company-logo {
    margin: 0 auto;
  }
  .promote {
    height: 36px;
    position: relative;
    display: block;
    max-width: 200px;
    text-align: center;
    margin: 10px auto;
  }
  .position {
    padding: 0px;
    margin: 30px 0;
  }
  .positions-wrap {
    margin: 0;
  }
}
</style>
