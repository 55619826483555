import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import store from "./store";
import router from "./router/index";
import VueFbCustomerChat from "vue-fb-customer-chat";
import localforage from "localforage";

Vue.use(VueFbCustomerChat, {
  page_id: 104779491277069, //  change 'null' to your Facebook Page ID,
  theme_color: "#0084ff", // theme color in HEX
  locale: "en_US", // default 'en_US'
});

import Popup from "vue-bgl-popup";
import vuetify from "./plugins/vuetify";

Vue.component("popup", Popup);

Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.prototype.$localforage = localforage;


new Vue({
  store: store,
  router: router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
