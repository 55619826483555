<template>
  <div>
    <div
      v-if="!getTags"
      class="loader-design"
    >
      <loader />
    </div>
    <div v-else>
      <h3 style="margin-bottom: 20px">Tags</h3>
      <div
        v-for="tag in getTags"
        :key="tag._id"
        class="white-box"
      >
        <div>
          <p
            class="comp-name"
            style="font-size: 17px; display: inline-block; vertical-align: top"
          >
            {{ tag.tagName }}
          </p>
          <div
            style="display: inline-block"
            @click="removeTag(tag._id)"
          >
            <SingleIndustry
              :label="'Remove'"
              :color="'white'"
              :add-new="true"
            />
          </div>
          <hr
            class="solid"
            style="margin-bottom: 10px"
          />
          <!--              <a @click="removeTag(tag._id)" style="color: red">Remove</a>-->
          <singleTag :tag="tag" />
        </div>
      </div>
      <!--        <p class="comp-new-left" @click="pop=true">+ Add New Tag <br></p></div>-->
      <div
        @click="pop = true"
        v-if="!pop"
      >
        <SingleIndustry
          :label="'+ Add New Tag'"
          :color="'white'"
          :add-new="true"
        />
      </div>
    </div>
    <div
      class="w-form white-box"
      v-if="pop"
    >
      <input
        type="text"
        class="text-field w-input"
        maxlength="256"
        placeholder="Tag name"
        v-model="newTag.name"
        id="tagName"
      />
      <vue-tags-input
        v-model="txt"
        :tags="tags || []"
        @tags-changed="addTag($event)"
        :placeholder="'Add keyword'"
        :allow-edit-tags="true"
        class="tags-keywords"
      />
      <a
        class="delete-btn w-button"
        style="background: #3fcfff; margin-bottom: 30px"
        @click="createTag()"
      >Add Tag</a>
      <loader v-if="loading" />
      <br />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "@/components/loader";
import singleTag from "../components/singleTag";
import VueTagsInput from "@johmun/vue-tags-input";
import SingleIndustry from "../components/SingleIndustry";

export default {
  name: "Tags",
  components: { loader, singleTag, VueTagsInput, SingleIndustry },
  async mounted() {
    //await this.$store.dispatch('fetchBoard', this.$route.params.id);
    await this.$store.dispatch("fetchTags", this.$route.params.id);
    await this.$store.commit("setSignInit");
  },
  methods: {
    async addTag(newTags) {
      this.newTag.keywords = newTags.map((t) => t.text + " ").join();
      console.log(this.newTag.keywords);
    },
    async createTag() {
      console.log(this.newTag);
      await this.$store.dispatch("createTag", this.newTag);
    },
    async removeTag(tagId) {
      console.log("remove the tag with the id:");
      console.log(tagId);
      await this.$store.dispatch("removeTag", tagId);
    },
  },
  data() {
    return {
      newTag: {
        name: "",
        keywords: "",
      },
      tags: [],
      pop: false,
      txt: "",
      loading: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters(["getTags", "getLoading", "getSelectedBoard"]),
  },
  watch: {
    async getLoading() {
      console.log("loading changed to:");
      console.log(this.getLoading);
      switch (this.getLoading) {
        case 1:
          this.loading = true;
          break;
        case 2:
          this.loading = false;
          this.pop = false;
          await this.$store.dispatch("fetchTags", this.getSelectedBoard._id);
          this.newTag.name = "";
          this.newTag.keywords = "";
          this.txt = "";
          break;
        case 3:
          this.error = true;
          this.loading = false;
          break;
      }
    },
  },
};
</script>

<style scoped>
.add-btn {
  border: solid 1px #047cff;
  background: white;
  color: #047cff;
}

.unselected-row {
  background: #f7f7f7;
  border-radius: 5px;
  /*margin-bottom: 0px;*/
  margin-top: -10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  padding: 15px 20px;
}

hr.solid {
  border-top: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 70%;
}

.unselected-row .comp-btn-wrap {
  position: absolute;
  top: 11%;
  right: 1%;
  bottom: auto;
  padding-top: 7px;
}

.loader-design {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comp-row {
  padding-right: 0;
  padding-left: 0;
}
</style>
