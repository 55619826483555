var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"results"},[_c('div',{staticClass:"_1200"},[(_vm.company)?_c('div',{staticClass:"comp-wrap"},[_c('div',{staticClass:"company-profile-wrapper"},[(_vm.company.logo)?_c('div',{staticClass:"company-logo",style:({
            'background-image': `url(${_vm.company.logo.imageURL})`,
          })}):_vm._e(),_c('div',{staticClass:"company-txt-wrapper"},[_c('p',{staticClass:"name-of-job-list"},[_vm._v(_vm._s(_vm.company.name))]),(_vm.compPositions)?_c('span',{staticClass:"gray-txt"},[_vm._v(_vm._s(_vm.compPositions.length)+" Jobs")]):_vm._e(),_c('p',{staticClass:"company-bio"},[_vm._v(_vm._s(_vm.company.tagline))]),_c('p',{staticClass:"gray-txt"},[_vm._v(_vm._s(_vm.company.address))]),_c('p',{staticClass:"company-bio"},[_vm._v(_vm._s(_vm.company.description))])])]),_c('div',{staticClass:"job-counter"})]):_c('div',{staticStyle:{"color":"red","text-align":"center","width":"100%"}},[_vm._v(" Sorry, we couldn't find what you're looking for ")]),_c('div',{staticClass:"positions-wrap"},[(!_vm.compPositions)?_c('loader'):_c('div',_vm._l((_vm.compPositions),function(position){return _c('div',{key:position._id,staticClass:"position"},[_vm._v(" "+_vm._s(position.position)+" "),(
              _vm.getSelectedBoard.promotedJobs.find(
                (p) => p.itemRefID == position._id
              )
            )?_c('a',{staticClass:"promote promoted",on:{"click":function($event){return _vm.unpromote(position)}}},[_vm._v("Promoted")]):_c('a',{staticClass:"promote",on:{"click":function($event){return _vm.promote(position)}}},[_vm._v("Promote")])])}),0)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }