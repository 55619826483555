<template>
  <v-app
    class="body"
    id="app"
  >
    <router-view v-slot="{ Component, route: r }">
      <KeepAlive>
        <component
          :is="Component"
          :key="r.name"
        />
      </KeepAlive>
    </router-view>
  </v-app>
</template>

<script>
import "./assets/css/normalize.css";
import "./assets/css/webflow.css";
import "./assets/css/freshboard-city-user.webflow.css";
import "./assets/css/compenies-selection-new.css";

export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Freshboard City";
      },
    },
  },
};
</script>

<style lang="scss">
/* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  Remove list styles (bullets/numbers)
  in case you use it with normalize.css
*/
ol,
ul {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* Removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

#app {
  font-family: revert;

  a:where(:not(.comp-copy, .pubbtn, .menu-tabs, .router-link-exact-active, .delete-btn)),
  .info {
    color: unset;
    border: revert !important;
    background: unset !important;
    line-height: unset;
  }

  .name,
  .delete-btn,
  .bar {
    color: #fff;
  }

  // font-family: revert;
  // font-weight: unset;
  display: revert;
  position: revert;

  .v-application {
    .info-line {
      margin-bottom: revert;
    }
  }

  .v-label.v-label--active {
    margin: -10px 0 0 0px;
  }
}

.comp-copy-fix {
  display: inline-block;
  margin-top: 6px;
}

.login-btn {
  color: white !important;
  text-align: center;
}

.new-logo-up {
  width: 100%;
  border: #e7e7e7 1px dashed;
  border-radius: 4px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
  position: relative;
  color: gray;
  line-height: 1.3;
  transition: ease 200ms all;
  margin-bottom: 20px;
}

.new-logo-up:hover {
  border: #047cff 1px dashed;
}

.new-logo-up span {
  font-size: 12px;
}

.input-image img {
  max-width: 90%;
}

.new-logo-up>input[type="file"] {
  opacity: 0;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
</style>
