import { render, staticRenderFns } from "./ManualCompanies.vue?vue&type=template&id=05fe35cb&scoped=true&"
import script from "./ManualCompanies.vue?vue&type=script&lang=js&"
export * from "./ManualCompanies.vue?vue&type=script&lang=js&"
import style0 from "./ManualCompanies.vue?vue&type=style&index=0&id=05fe35cb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05fe35cb",
  null
  
)

export default component.exports